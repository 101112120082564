.UniHomePageMainContainer {
  display: grid;
  // grid-template-rows: minmax(100svh, max-content) repeat(auto-fill, auto);
  // height: calc(97vh - 3%);
  // height: calc(97svh - 3%);
  height: 100dvh;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: Poppins;
  grid-auto-rows: auto; // margin-bottom: 100px;

  >*:not(:last-child):not(.StartFreeAndImgContainer) {
    min-height: 81svh;
    height: max-content;
  }

  >div:last-child {
    display: flex;

  }


  >div:nth-child(odd) {
    background-color: #181818;
  }
  >div:nth-child(even) {
    background-color: black;
}
}

.whatsAppPageHeader{
  background: linear-gradient(90deg,rgba(27,111,34,1), rgba(48,196,60,1) 100%, 100%, rgba(40,164,50,1) 100%);
  color: white;
  padding: 0.4rem 0;
  min-height: unset !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top:0;
  z-index: 2;
}

.HomePageFirstRowItemContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  >img {
    width: 250px;
  }

  >span {
    color: #4ae0db;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 77px;
    letter-spacing: 0.28px;
  }

  >p {
    color: #ffffff;
    text-align: center;
    font-family: Poppins;
    font-size: var(--header);
    font-style: normal;
    font-weight: 700;
    line-height: var(--headerLineHeight);
    letter-spacing: 0.75px;
    max-width: 60%;
  }
}

.HomePageSecondAndThirdRowItemContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 3.5rem 0 0 0;

  >div {
    >p {
      font-family: Poppins;
      font-size: var(--header);
      font-style: normal;
      font-weight: 700;
      line-height: var(--header);
      letter-spacing: 0.96px;
      width: 70%;
    }
  }

  >img {
    margin: 0 auto;
  }
}

.HomePageSecondAndThirdRowItemContainer .SecondRowStartFreeDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  max-width: 90%;
  min-width: 330px;
  margin: 0 auto;
  // padding-left: 30%;
}

.SimpleImg {
  width: 80%;
  max-width: 800px;
}

// .ThirdRowWhatsappChatContainer {
//   // border: 1px solid lightgrey;
//   background-color: #313333;
//   // height: 80%;
//   width: 90%;
//   max-width: 450px;
//   align-self: flex-end;
//   border-top-left-radius: 20px;
//   border-top-right-radius: 20px;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: flex-start;
//   margin: 0 auto;

//   >span {
//     display: flex;
//     height: 14%;
//     width: 100%;
//     border-bottom: 1px solid lightgray;
//     justify-content: space-around;
//     margin: 10px 0 0;

//     > :nth-child(2) {
//       margin-left: 20px;
//       height: 66px;
//     }

//     > :last-child {
//       height: 15px;
//       margin-right: -17px;
//     }
//   }
// }

// .ThirdRowStartFreeDiv {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   max-width: 90%;
//   margin: 0 auto;
//   // text-align: center;
//   padding: 3.5rem 0 3.5rem;
// }

// .ThirdRowWhatsappChatContainer .WhatsAppChat {
//   margin: 2rem auto;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
//   align-items: center;
//   // height: 70%;
//   padding: 0 2rem;

//   >p:first-child {
//     background-color: #17c3ce;
//     padding: 1.3rem 1.3rem;
//     border-radius: 25px;
//     border-bottom-right-radius: 0;
//   }

//   >div:last-child {
//     background-color: #ffffff;
//     color: black;
//     margin-top: 6%;
//     padding: 3rem 1rem;
//     border-radius: 25px;
//     border-bottom-left-radius: 0;
//   }
// }

.ForthRowFiguresContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-family: Poppins;
  font-weight: 700;
  padding: 6rem 1rem;
  gap: 5rem;

  >p {
    font-family: Poppins, sans-serif;
    text-align: center;
    font-size: var(--header);
    font-style: normal;
    font-size: var(--header);
    letter-spacing: 0.65px;
    line-height: clamp(1rem, 8vw, 7rem);
    &:nth-child(1) span{
      font-family: Poppins !important;
      font-weight: 500 !important;
    }
  }
}

.ForthRowFiguresContainer .FiguresContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
}

.FifthRowMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 8rem 0;
  min-height: 45svh !important;
  min-height: 45vh !important;

  >p {
    max-width: 60%;
    text-align: center;
    font-family: Poppins;
    font-size: var(--header);
    font-style: normal;
    font-weight: 700;
    line-height: var(--headerLineHeight);
    letter-spacing: 1.28px;
  }
}

.HebClass {
  direction: rtl;
}

// .SixthRowMainContainer {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   flex-wrap: wrap;
//   gap: 2rem;
//   padding: 5rem 1rem;

//   >p {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;
//     color: white;
//     font-size: 45px;

//     > :first-child {
//       text-transform: capitalize;
//       font-weight: 700;
//       text-align: center;
//     }
//   }

//   >div {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//     align-items: center;
//     gap: 4rem;
//     padding: 4rem 0;
//   }
// }

// .SeventhRowMainContainer {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   gap: 5rem;
//   padding: 3rem 0 9rem;
//   color: white;

//   >p {
//     max-width: 60%;
//     font-size: 45px;
//     font-weight: 700;
//     line-height: 57px;
//     letter-spacing: 0.45px;
//   }
// }

// .SeventhRowMainContainer .SeventhRowAboutInfoCardContainer {
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;
//   gap: 2rem;
// }

.EighthRowMainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem 0rem;
  background-color: #4ae0db;
  gap: 3rem;

  >p {
    width: 50%;
    text-align: center;
    font-family: Poppins;
    font-size: var(--header);
    font-style: normal;
    font-weight: 700;
    line-height: var(--headerLineHeight);
    letter-spacing: 0.64px;
  }
}

// @media (max-width:1780px) {

//   .UniHomePageMainContainer {
//     height: 95vh;
//     height: 95dvh;
//     height: 95svh;
//   }

// }

@media (max-width: 1378px) {
  .HomePageSecondAndThirdRowItemContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 0;
  }

  .HomePageSecondAndThirdRowItemContainer .SecondRowStartFreeDiv {
    padding-left: 0;
    align-items: center;
    // margin-bottom: 2rem;
    grid-row-start: 1;
  }

  .ThirdRowStartFreeDiv {
    text-align: center;
  }

  // .SeventhRowMainContainer {
  //   >p {
  //     text-align: center;
  //   }
  // }

  .HomePageSecondAndThirdRowItemContainer {
    >div {
      >p {
        font-size: clamp(3.5rem, 3vw + 1rem, 12rem);
        font-style: normal;
        line-height: clamp(3.5rem, 3vw + 1rem, 12rem);
        text-align: center;
      }
    }
  }
}

// @media (max-width: 1050px) {
//   .UniHomePageMainContainer {
//     height: 95.5vh;
//     height: 95.5dvh;
//     height: 95.5svh;
//   }
// }

// @media (max-width: 780px) {
//   .UniHomePageMainContainer {
//     height: 96vh;
//     height: 96dvh;
//     height: 96svh;
//   }
// }


@media (max-width: 500px) {

  // .UniHomePageMainContainer {
  //   height: 92.5vh;
  //   height: 92.5dvh;
  //   height: 92.5svh;
  // }

  .HomePageFirstRowItemContainer {
    >span {
      font-size: 22px;
      line-height: 77px;
      letter-spacing: 0.28px;
    }

    >p {
      font-size: clamp(2rem, 2.5vw + 1rem, 9rem);
      line-height: clamp(2.5rem, 1.5vw + 1rem, 9rem);
      letter-spacing: 0.75px;
      max-width: 90%;
    }
  }

  .HomePageSecondAndThirdRowItemContainer {
    >div {
      >p {
        font-size: clamp(3rem, 3vw + 1rem, 12rem);
        line-height: clamp(3rem, 3vw + 1rem, 12rem);
        letter-spacing: 0.96px;
        width: 85%;
      }
    }
  }

  // .ThirdRowWhatsappChatContainer .WhatsAppChat {
  //   >p:first-child {
  //     font-size: medium;
  //     padding: 1.3rem 1.3rem;
  //   }

  //   // > p:scond-child {
  //   //   font-size: small;
  //   //   padding: 1.3rem 1.3rem;
  //   // }
  // }

  .ForthRowFiguresContainer {
    >p {
      // font-size: clamp(2rem, 2vw + 1rem, 9rem);
      letter-spacing: 0.65px;
      // width: 80%;
    }
  }

  .FifthRowMainContainer {
    >p {
      max-width: 90%;
      font-size: clamp(2rem, 2.5vw + 1rem, 9rem);
      line-height: clamp(2.2rem, 2.5vw + 1rem, 9rem);
      letter-spacing: 1.28px;
    }
  }

  // .SixthRowMainContainer {
  //   >p {
  //     max-width: 90%;
  //     color: white;
  //     font-size: clamp(2rem, 2vw + 1rem, 9rem);
  //   }
  // }

  // .SeventhRowMainContainer {
  //   >p {
  //     max-width: 90%;
  //     font-size: clamp(2rem, 2vw + 1rem, 9rem);
  //     line-height: clamp(2.2rem, 2.5vw + 1rem, 9rem);
  //     letter-spacing: 0.45px;
  //   }
  // }

  .EighthRowMainContainer {
    >p {
      width: 90%;
      text-align: center;
      font-family: Poppins;
      font-size: clamp(2rem, 2vw + 1rem, 9rem);
      line-height: clamp(2.5rem, 2vw + 1rem, 9rem);
    }
  }

  .SimpleImg {
    margin-top: 2.5rem !important;
  }
}

.ServiceDataCardsContainer{
  display: flex;
  width: 100%;
  max-width: 1200px;
  justify-content: center;
  flex-wrap: wrap;
  gap: 7rem;
}