.spinner {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    // margin: 2% 0 0;
    // margin: 0 0 3rem;
    align-self: center;

}


.spinner::before,
.spinner:after {
    content: "";
    position: absolute;
    border-radius: inherit;
    display: block;
}

.spinner:before {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #FF58B2 0%, var(--defaultPurple) 100%);
    animation: spin8932 .5s infinite linear;
    transition: opacity 1s ease-in-out;
    opacity: 1;
}

.spinner.fadeOut::before {
    opacity: 0;
}


.spinner:after {
    width: 85%;
    height: 85%;
    z-index: 100;
    background-color: var(--after-color);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spin8932 {
    to {
        transform: rotate(360deg);
    }
}

.statusIconStylesShown {
    position: absolute;
    z-index: 200;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;
    opacity: 0;
    animation: showStatus 1s forwards linear;
}

.statusIconStylesHidden {
    opacity: 0;
}

@keyframes showStatus {
    to {
        opacity: 1;
    }
}