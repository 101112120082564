.whatsAppRegistrationMainContainer{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 80%;
width: 90%;
max-width: 400px;
>hr{
    width:100%;
}
}

.whatsAppRegistrationHeader{
    --secondary-header-color: #E546A1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    >span:first-child{
        // font-size: clamp(2rem, 3vw, 4rem);
        font-size: clamp(3rem, 10vw, 5rem);
        font-weight: 600;
        color: white;
        border-radius: 50%;
        background-color: var(--brandPurple);
        width:clamp(80px, 10vw, 125px);
        height: clamp(80px, 10vw, 125px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    >h1{
        text-align: center;
        font-size: clamp(2rem, 5vw, 3rem);
    }
    >p{
        // font-size: clamp(1.5rem, 2vw, 3rem);
        font-weight: 600;
        color: var(--secondary-header-color);
        margin: 0;
        text-align: center;
        font-size: clamp(0.8rem, 2vw, 1.2rem);
    }
}

.whatsAppRegistrationSecondaryContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    gap: 1rem;
    padding: 1rem;
    >h3{
        font-size: 1.4rem;
    }
    >form{
        
        display: flex;
        flex-direction: column;
        gap: 1rem;


        .emailInputContainer{
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }
        .emailInput{
            border: 1px solid grey;
            padding: 0.5rem 1rem;
            border-radius: 7px;
            direction: ltr;
            >input{
                border: none;
                outline: none;
                width: 50%;
            }
        }

        .PhoneInput{
            border: 1px solid grey;
            padding: 0.5rem 1rem;
            border-radius: 7px;
            direction: ltr;
            >input{
                border: none;
                outline: none;
                width: 50%;
                font-size: clamp(0.9rem, 3vw, 1.2rem);
            }
            >div{
                >div:first-of-type{
                    box-shadow: none;
                    width: clamp(25px, 4vw, 50px);
                    height: clamp(25px, 4vw, 50px);
                    background-color: transparent;
                }
                border-right: 1px solid grey;
                padding-right: 1rem;
            }
        }
        .continueButton {
            background-color: var(--brandPurple) !important;
            width: 100%;
            height: 56px;
            color: white;
            font-weight: 550;
            &:hover{
                background-color: var(--brandPurpleHover) !important;
            }
            >div{
                display: flex;
                padding-left:5%;
                // justify-content: space-between;
                width: 90%;
                align-items: center;
                >span{
                    flex:1;
                }
            }
            // :hover {
            //     all: unset !important;
            // }
        }
    }
}
.agreement{
    font-size: clamp(0.6rem, 2vw, 0.8rem);
    text-align: center;
    // margin-top: 1rem;
    >a{
        // text-decoration: none;
        color: var(--brandPurpleHover);
    }
}

.existingUser{
    font-size: clamp(0.7rem, 2vw, 1rem);
    >a{
        // text-decoration: none;
        color: var(--brandPurpleHover);
    }
}
.helperText {
    color: red;
}
.errorMessage {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    // text-align: right;
    // align-self:flex-end;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    
}