.UniContact{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.UniContactMainContainer {
  box-sizing: border-box;
  padding: 2% 0;
  background-color: #141515 !important;
  // width: 100%;
  gap: 20%;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Roboto;

  // flex: 1 1 auto;
}

.ContactInfoContainer {
  // height: 100%;
  display: flex;
  width: 30%;
  flex-direction: column;
  justify-content: space-between;
  font-weight: bold;

  // margin: 2%;
  >* {
    margin: 1% 0;
  }

  >img {
    width: 200px;
    height: 200px;
  }



  //   .description {
  //     font-size: 4px;
  //   }

  > :nth-child(2) {
    // // font-size: clamp(1.2rem, 1vw + 0.5rem, 2rem);
    // color: #A0A2A4;
    // font-family: Poppins;
    // font-size: 20px;
    // font-style: normal;
    // font-weight: 400;
    // line-height: 36px;
    // /* 180% */
    // text-transform: uppercase;
  }

  // > :nth-child(3) {
  // max-width: 60%;
  // line-height: 2rem;
  // font-size: 14px;
  // color: grey;
  // }
}

.ConcatInfoHeader {
  color: #ECEFF4;
  font-family: Poppins;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 57px;
  /* 126.667% */
  letter-spacing: 0.45px;
}

.ContactUsContainer {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: bold;

  >* {
    margin: 1% 0;
  }

  > :first-child {
    color: #FF58B2;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }

  > :nth-child(2) {
    font-size: clamp(0.5rem, 1vw + 1rem, 3rem);
  }
}

.HebClass {
  direction: rtl;
}

.ContactUsLabelAndInputContainer {
  width: 100%;
  margin: 3% 0;
  display: flex;
  flex-direction: column;

  >label {
    color: #A0A2A4;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  >input,
  >textarea {
    background: none;
    border: none;
    outline: none;
    color: white;
    border-bottom: 1px solid rgb(108, 108, 108);
    // border-bottom: 1px solid grey;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    // width: 0px;
    outline: none;
    padding: 3px 0px 3px 3px;
    margin: 5px 1px 3px 0px;
    resize: none;
  }

  >input:focus,
  >textarea:focus {
    outline: none;
    padding: 3px 0px 3px 3px;
    margin: 5px 1px 3px 0px;
    border-bottom: 1px solid rgb(55, 0, 184);
  }
}

.ContactUsSendMessageButton {
  background-color: white;
  margin-top: 2rem;
  text-transform: uppercase;
  color: black;
  border-radius: 50px;
  width: 190px;
  padding: 1.2rem 0.5rem;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.4px;
}

.UniContactMainContainer .ContactInfoContainer .ContactOptionsAndInfoContainer {
  >span:not(:last-child) {
    border-bottom: 1px solid rgb(108, 108, 108);
    width: 100%;
    max-width: 370px;
  }

  >span {
    margin-top: 10%;
    display: flex;
    gap: 8%;
    align-items: center;
    width: 64%;
    padding-bottom: 8%;

    >p {
      white-space: break-spaces;
    }
  }
}

.ContactInfoContainer .ContactInfoLetsChatButton {
  all:unset;
  text-align: center;
  margin-top: 2rem;
  text-transform: uppercase;
  background-color: #10B9AA;
  color: white;
  border-radius: 50px;
  width: 190px;
  padding: 1.2rem 0.5rem;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.4px;
  cursor: pointer;
}

.ContactInfoDescription {
  font-family: Poppins;
  font-style: normal;
  text-transform: uppercase;
  max-width: 70%;
  line-height: 2rem;
  font-size: 14px;
  color: grey;
}

@media (max-width: 1097px) {
  .UniContactMainContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    padding-bottom: 2rem;
  }

  .ContactInfoContainer {
    width: 82%;
    margin: 6% 0 20%;
  }

  .ContactUsContainer {
    width: 82%;

  }

  // .ContactUsSendMessageButton {
  //   width: 50%;
  // }
}

@media (max-width: 660px) {

  .ContactInfoDescription {
    max-width: 100%;
  }
}

@media (max-width: 400px) {

  .ContactInfoContainer {
    width: 75%;

  }

  .ContactUsContainer {
    width: 75%;

  }
}