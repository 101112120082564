.container {
    background-color: #B398EF;
    position: relative;
    // margin: 200px auto 0;
    color: white;
  }

  .containerLtr{
    direction: ltr;
    > * {
      direction: ltr;
    }
  }
  
  .logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    // max-width: 257px;
    // max-height: 250px;
    width: clamp(100px, 13vw, 200px);
    // height:100%;
  }
  
  .content {
    padding-top: 80px;
    padding-bottom: 100px;
    text-align: center;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 3%;
    direction: ltr;
    padding-top: clamp(50px, 10%, 200px);
    padding-bottom: 150px;
    // @media (min-width: 768px) {
    // }
  }
  
  .mainTitle {
    // direction: rtl;
    font-weight: bold;
    font-size: var(--fs-large);
    line-height: 40px;
    letter-spacing: 0.01em;
  
    // @media (min-width: 640px) {
    //   font-size: 36px;
    // }
  
    // @media (min-width: 768px) {
    //   font-size: 85px;
    //   line-height: 95px;
    // }
  }
  
  .subtitleWrapper {
    direction: rtl;
    display: flex;
    // justify-content: flex-end;
    align-items: flex-end;
    // flex-direction: column;
  }
  
  .subtitle {
    // direction: rtl;
    margin-top: 20px;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.01em;
    text-align: right;
    border-bottom: 1px solid white;
  
    // @media (min-width: 640px) {
    //   font-size: 24px;
    // }
  
    // @media (min-width: 768px) {
    //   margin-top: 34px;
    //   font-size: 40px;
    //   line-height: 60px;
    // }
  }
  
  .benefitsContainer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  
    @media (min-width: 768px) {
      margin-top: 144px;
      gap: 60px;
    }
  }
  
  .benefitItem {
    border: 1px solid black;
    border-radius: 20px;
    padding: 1.25rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1.25rem;
    max-width: 1300px;
    margin: 0 auto;
    &.ltr{
      @media (min-width: 768px) {
        flex-direction: row-reverse;

      }
      > * {
        text-align: left;
        direction: ltr;
      }
    }
  
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  
  .benefitImage {
    position: relative;
  }
  
  .benefitLogo {
    min-width: 100px;
    min-height: 100px;
    max-width: 260px;
    max-height: 260px;
    @media (min-width: 768px) {
      min-width: 242px;
      min-height: 242px;
    }
  }
  
  .benefitContent {
    color: black;
  
    @media (min-width: 1024px) {
      padding-right: 50px;
    }
  
    @media (min-width: 768px) {
      padding-right: 100px;
    }
  }
  
  .benefitHeader {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
    &.ltr{
      flex-direction: row-reverse;
    }
  }
  
  .benefitTitle {
    direction: rtl;
    font-size: 20px;
    line-height: 35px;
    letter-spacing: 0.01em;
    font-weight: bold;
    text-align: right;
    &.ltr{
      text-align: left;
      direction: ltr;
    }
    @media (min-width: 640px) {
      font-size: 24px;
    }
  
    @media (min-width: 768px) {
      font-size: 35px;
      line-height: 50px;
    }
  }
  
  .benefitNumber {
    font-size: 20px;
    line-height: 35px;
    letter-spacing: 0.01em;
    font-weight: bold;
    text-align: right;
    &.ltr{
      text-align: left;
      direction: rtl;
    }

    @media (min-width: 640px) {
      font-size: 24px;
    }
  
    @media (min-width: 768px) {
      font-size: 35px;
      line-height: 50px;
    }
  }
  
  .benefitDescription {
    direction: rtl;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-align: right;
    font-weight: bold;
    &.ltr{
      direction: ltr;
      text-align: left;
    }
  
    @media (min-width: 640px) {
      font-size: 18px;
    }
  
    @media (min-width: 768px) {
      font-size: 28px;
      line-height: 36px;
    }
  }
  
  .bonusTitle {
    // direction: rtl;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.01em;
    font-weight: bold;
    margin: 30px 0;
  
    @media (min-width: 768px) {
      font-size: 85px;
      line-height: 95px;
      margin: 60px 0;
    }
  }
  
  .bonusContainer {
    display: flex;
    flex-direction: column;
    max-width: 1300px;
    margin: 0 auto;
    gap: 20px;
  
    @media (min-width: 768px) {
      flex-direction: row;
      gap: 34px;
    }
  }
  
  .bonusItem {
    border: 1px solid black;
    border-radius: 20px;
    padding: 1.25rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1.25rem;
    flex: 1;
  }
  
  .bonusHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &.ltr{
      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }
    }
  
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  
  .bonusImageWrapper {
    position: relative;

  }
  
  .bonusLogo {
    max-width: 112px;
    max-height: 112px;
  
    @media (min-width: 768px) {
      min-width: 112px;
      min-height: 112px;
    }
  }
  
  .bonusItemTitle {
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 0.01em;
    font-weight: bold;
    text-align: right;
    color: black;
    margin-top: 1.25rem;
    &.ltr{
      text-align: left;
      @media (max-width: 768px) {
        text-align: center;
        direction: ltr;
      }
    }
    @media (min-width: 768px) {
      font-size: 35px;
      line-height: 50px;
      margin-top: 0;
      
    }
  }
  
  .bonusDescription {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-align: right;
    font-weight: bold;
    color: black;
    &.ltr{
      @media (max-width: 768px) {
        text-align: left;
        direction: ltr;
      }
    }
    // direction: rtl;
    @media (min-width: 768px) {
      font-size: 28px;
      line-height: 36px;
    }
  }
  
  .finalDescription {
    // direction: rtl;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.01em;
    font-weight: normal;
    text-align: center;
    margin-top: 30px;
    max-width: 1177px;
    margin-left: auto;
    margin-right: auto;
  
    @media (min-width: 768px) {
      font-size: 40px;
      line-height: 42px;
      margin-top: 60px;
    }
  }
  
  .ctaButton {
    width: 100%;
    min-height: 60px;
    background-color: #231154;
    margin-top: 50px;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 0.04em;
    font-weight: bold;
    text-align: center;
    border-radius: 0.75rem;
    color: white;
    &.ltr{
      @media (min-width: 768px) {
        direction: ltr;
      }
    }
  
    @media (min-width: 640px) {
      width: 512px;
    }
  
    @media (min-width: 768px) {
      min-height: 115px;
      margin-top: 100px;
      font-size: 36px;
      line-height: 82px;
    }
  }