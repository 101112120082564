.extensionThankYouPageMainContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 3rem;
    color: white;
    
}

.thankYouPageContainer{
    border-radius: 30px;
    height: 40vw;
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 0 0 0;
    // gap: 1.5rem;
    text-align: center;
    font-family: Poppins, sans-serif;
    > * {
        &:not(:first-child) {
          margin-bottom: 3rem;
        }
      }
    >img{
        max-width: 125px;
        max-height: 125px;
        width: 20% !important; 
        height:20% !important;
        margin-bottom: 1.5rem ;
    }
    >h1{
        font-family: Poppins;
        font-size: clamp(1.8rem, 3vw, 3.5rem);
        letter-spacing: 4px;
    }
    >p{
        max-width:700px;
        width:70%;
        font-size: clamp(0.8rem, 3vw, 1.2rem);
        word-spacing: 2px;
    }
    >h3{
        max-width:700px;
        width:70%;
        font-size: clamp(1.1rem, 4vw, 1.3rem);
        word-spacing: 2px;
        letter-spacing: 2px;
    }
}