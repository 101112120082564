.chatPlanPricingPage{
    height: 100dvh;
    // max-height: 750px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    >div{
        height: 100%;
        overflow: hidden;
        padding: 0 !important;
        >div{
            height: 100%;
            padding: 0 1rem;
            overflow-y: auto; 
            overflow-x:hidden;
            /* width */

            >div{
                padding: 0 1rem !important;
            }
            &::-webkit-scrollbar {
                display: block !important;
                width: 5px !important;
              }
              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #6a6a6aaa;
                border-radius: 10px;
              }
              
              /* Handle on hover */
             &::-webkit-scrollbar-thumb:hover {
                background: #555;
              }
        }
    }
}

@media (max-width: 350px){
    .chatPlanPricingPage{
        >div{
            >div{
                >div{
                    width: unset !important;
                    min-width: 250px !important;
                    >div{
                        width:unset !important;
                    }
                }
            }
        }
    }
}