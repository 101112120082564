.UniFooterContainer {
    // width: 100%;
    background-color: black !important;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem 0 1.5rem;
    color: rgba(255, 255, 255, 0.324);
    gap: 30%;
    

    // flex: 1 1 auto;
    >:first-child {
        font-size: clamp(0.7rem, 2vw, 1rem);
        white-space: normal;
        max-width: 40%;
    }

}

.UniFooterContainer .MediaRedirectionsSpan {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 20%;
    min-width: 150px;

    >a {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        // background-color: rgb(122 122 122 / 9%);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width:1000px) {
    .UniFooterContainer .MediaRedirectionsSpan {
        gap: 5px;
    }
}

@media (max-width:523px) {
    .UniFooterContainer {
        gap: 1%;
    }
}

@media (max-width:360px) {
    .UniFooterContainer {
        justify-content: center;
    }

}