.PricingPlanCardsContainer {
  display: flex;
  flex-direction: column;
  background-color: #2E2E2E;
  font-family: Poppins;
  padding: 2rem;
  color: white;
  // border: 1px solid rgba(255, 255, 255, 0.21);
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.286);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  min-width: 308px;
  max-width: 340px;
  // width: 100%;

  >* {
    box-sizing: border-box;
  }

}

.buttonAndPlanDataContainer {

  display: flex;
  flex-direction: column;
  // gap: 2rem;
  width: 283px;
  >p{
    text-decoration: underline;
    margin: 1.4rem 0 0.5rem;
  }
  >ul {
    list-style-type: none;
    min-height: 340px;
    padding: 0;

    >div {
      display: flex;
      flex-direction: column;
      margin: 0.5rem 0;
      // >li {
      //   

      // }

      >li:first-child::before {
        content: "\2714\fe0e\0020";
        // content: "\2714\0020";
        color: white !important;

      }

      >li:nth-child(2) {
        margin-left: 1rem;
        font-size: 12px;
        color: rgba(113, 113, 113, 0.727);
      }
    }
  }

}

.NotIncluded {

  color: rgba(113, 113, 113, 0.476) !important;

  &::before {
    color: rgba(113, 113, 113, 0.476) !important;
  }

}

.PricingPlanButtonAndSpan {
  display: flex;
  flex-direction: column;
  gap: 2%;

  >span {
    color: #1C99FE;
    margin: 1rem;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
}

// .PricingPlanCardsContainer:hover {
//   box-shadow: 8px 7px 0px 0px #17c3ce;
//   transition: box-shadow 0.2s ease-in-out;

//   >div {
//     >button {
//       -webkit-appearance: none;
//       -moz-appearance: none;
//       appearance: none;
//       transition: all 0.2s ease-in-out;
//       background-color: #17c3ce;
//       // color: white;
//     }
//   }
// }

.PricingPlanCardsContainer .PlanTypeContainer {
  display: flex;
  // color: #17c3ce;
  justify-content: flex-start;
  // gap: 40%;
  padding: 1rem 0;
  font-size: 26px;
  text-transform: uppercase;

  >span {
    transition: all 0.2s ease-in-out;
  }
}

.PricingPlanCardPriceNumber {
  font-size: 26px;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white !important;
  margin-bottom: 2rem;

  >span:last-child {
    font-size: 16px;
  }
  >p{
    margin: 1rem 0 0;
    font-size: 16px;
    font-weight: 400;
    height: 100px;
    font-family: Poppins, sans-serif;
  }
  >a{
    margin-top: 1rem;
  }
}

.PricingPlanCardPriceNumber_RTL {
  >p {
    direction: rtl;
  }
}

.PricingPlanCardPriceNumber .priceNumber {
  font-size: 30px;
  font-weight: bold;
}

.PricingPlanCardsContainer .PricingPlanTryFreeButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-weight: 700;
  width: 100%;
  font-family: Roboto;
  padding: 1.3rem 2rem;
  font-size: 14px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: white;
  outline: none;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.FreeCard {
  color: var(--defaultTurquoise);
}

.BasicCard {
  color: var(--defaultPurple);
}

.MediumCard {
  color: var(--defaultOrange);
}

.PermiumCard {
  color: var(--defaultPink);
}

.FreeButton {
  background-color: var(--defaultTurquoise);

  &:active {
    transition: active 0s ease-in-out;
    box-shadow: 2px 2px 2px inset black;
    background-color: var(--defaultTurquoise) !important;
  }
}

.BasicButton {
  background-color: var(--defaultPurple);

  &:active {
    transition: active 0s ease-in-out;
    box-shadow: 2px 2px 2px inset black;
    background-color: var(--defaultPurple) !important;
  }
}

.MediumButton {
  background-color: var(--defaultOrange);

  &:active {
    transition: active 0s ease-in-out;
    box-shadow: 2px 2px 2px inset black;
    background-color: var(--defaultOrange) !important;
  }
}

.PermiumButton {
  background-color: var(--defaultPink);

  &:active {
    transition: active 0s ease-in-out;
    box-shadow: 2px 2px 2px inset black;
    background-color: var(--defaultPink) !important;
  }
}

.fairUseLink{
  color: rgba(255, 255, 255, 0.39);
  font-size: clamp(0.5rem, 3vw, 1rem);
}

@media (max-width: 420px) {
  .PricingPlanCardsContainer {
    // width: 310px;
    padding: 1rem;
  }
}

@media (max-width: 345px) {
  .PricingPlanCardsContainer {
    min-width: 296px;
    width: 296px;
    margin: 0 auto;
  }

  .buttonAndPlanDataContainer {
    width: 266px;
  }

}