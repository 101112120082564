$small-font: clamp(10px, 2.5vw, 2rem);

.servicesCompareContainer {
    display: grid;
    grid-template-columns: 2fr 1.5fr 2fr; // Adjust the fractions based on your layout requirements
    // border: 1px solid #ffffff; // Use the color that fits your design
    background-color: #000000; // Use the color that fits your design
    color: #ffffff; // Use the color that fits your design
    max-width: 1500px; // Set to the maximum width you need
    margin: 5rem auto;
    width: 100vw;
    .seperatedAIsTitle{
        font-size: clamp(14px, 4vw, 3rem);
        grid-column-start: 2;
        grid-column-end: 2;
        text-align: center;
        border-right: 1px solid grey;
        border-left: 1px solid grey;
        border-bottom: 1px solid grey;
        padding: 5% 1rem;

        >span{
            display: flex;
            flex-direction: column;
        }
    }
    .unichatTitle{
        font-size: clamp(1.5rem, 6vw, 5rem);
        color: var(--highlightTurquoise);
        display:flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid grey;
        grid-column-start: 3;
        grid-column-end: 3; 
        text-align: center;
        padding: 5%;
    }
  
    .servicesName{
        font-size: $small-font;
        grid-column-start: 1;
        grid-column-end: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-top: 1px solid grey;
        border-bottom: 1px solid grey;
        padding: 5% 0;
        >span{
            padding: 2%;
        }
    }

    .servicesPrices{
        font-size: $small-font;
        grid-column-start: 2;
        grid-column-end: 2;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: 1px solid grey;
        border-right: 1px solid grey;
        border-bottom: 1px solid grey;
        padding: 7% 0;
        >span{
            padding: 2%;
        }
    }

    .included{
        font-size: $small-font;
        grid-column-start: 3;
        grid-column-end: 3;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 1px solid grey;
        // gap: 3%;
        padding: 5% 0;
        >span{
            padding: 2%;
        }
    }
    .startingTotal{
        padding: 5% 0;
        font-size: $small-font;
        display:flex;
        align-items: center;
        padding: 0 2%;
    }

    .unichatPrice{
        padding: 5% 0;
        font-size: $small-font; 
        text-align: center;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .multiAIPrice{
        padding: 5% 0;
        font-size: $small-font; 
        text-align: center;
        border-left: 1px solid grey;
        border-right: 1px solid grey;
        display:flex;
        justify-content: center;
        align-items: center;
    }
  }
  