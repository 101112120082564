// :root {
//     --header: clamp(3rem, 2.5vw + 1rem, 9rem);
//     --headerLineHeight: clamp(3rem, 2.5vw + 2rem, 9rem);
// }

.UniMidJourneyPageMainContainer {
    display: grid;
    grid-template-rows: repeat(auto-fit, auto);
    height: calc(97vh - 3%);
    height: calc(97dvh - 3%);
    height: calc(97svh - 3%);
    // width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: Poppins;

    >*:not(:last-child()) {
        min-height: 100svh !important;
        height: max-content !important;
    }

    >div {
        grid-column: 1 / -1;
    }

    >div:nth-child(odd) {
        background-color: #181818;
    }
    >div:nth-child(even) {
        background-color: black;
    }
}

.ShowcaseTitle {
    >p {
        text-align: center;
        color: white;
        font-size: var(--header);
        line-height: var(--headerLineHeight);
        letter-spacing: 0.45px;
        margin: 9rem 0;
    }
}

.ExampleCardsSectionContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    margin: 0 auto;
    width: 80%;

    >div {
        width: fit-content;
        height: 100%;
    }
}

@media (max-width: 1350px) {
    .ExampleCardsSectionContainer {
        width: 100%;
    }
}

@media (max-width: 1700px) {
    .UniMidJourneyPageMainContainer {
        height: calc(97vh - 2%);
        height: calc(97dvh - 2%);
        height: calc(97svh - 2%);
    }
}

@media (max-width: 1190px) {
    .UniMidJourneyPageMainContainer {
        height: calc(97vh - 1%);
        height: calc(97dvh - 1%);
        height: calc(97svh - 1%);
    }

    .ExampleCardsSectionContainer {
        grid-template-columns: 1fr;
    }

}

@media (max-width: 500px) {
    .UniMidJourneyPageMainContainer {
        height: calc(92vh);
        height: calc(92dvh);
        height: calc(92svh);
    }
}