.paymentPageMainContainer{
    height: 100dvh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    background-color: white;
    .inputsContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        
        // flex-direction: column;
        gap:1rem;
        width: 70vw;
        max-width: 500px;
        >div{
            display: flex;
            flex-direction: column;
        }
    }
}