.AboutAccordionMainContainer {
  display: flex;
  flex-direction: column;
  max-width: 85%;
  // height: 550px;
}

.HebClass {
  direction: rtl;
}

@media (max-width:600px) {
  .AboutAccordionMainContainer {
    // height: 900px;
  }
}

@media (max-width:350px) {
  .AboutAccordionMainContainer {
    max-width: 76%;
    // height: 900px;
  }
}