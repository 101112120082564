.newSaleMainFlexContainer{
    display: flex;
    flex-direction: column;
    height: 94dvh;
    width: 100vw;
    overflow-x:hidden;
    overflow-y: auto;
}
.newSaleMainContainer{
    display: flex;
    flex-direction: column;
    // align-items: center;

    direction: rtl;
    position: relative;
    font-family: 'Rubik', sans-serif;

    h1, h2, h3, h4, h5, h6 {
        font-weight: 500;
    }

    p, span, div {
        font-weight: 400;
    }
}
.langMenuContainer{
    position: sticky;
    top: 10px;
    right: 10px;
    z-index: 100;
}

