// Add this at the top of the file
.shekelComponent {
  font-family: 'Rubik', sans-serif;

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }

  p, span, div {
    font-weight: 400;
  }
}

// For headers
h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

// For other text
p, span, div {
  font-weight: 400;
}

.container {
  background-color: black;
  width: 100%;
  color: white;
  
}

.containerLtr{
  direction: ltr;
}

.textCustomColor {
  background: linear-gradient(260.99deg, #1C99FE 6.95%, #7644FF 50.91%, #FD4766 90.83%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.content {
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  padding: calc(var(--space-lg) - 2rem) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box {
  background-color: rgba(20, 21, 21, 0.6);
  border-radius: 20px;
  padding: var(--space-md);
  max-width: 1720px;
  margin: 0 auto;
  position: relative;

  @media (min-width: 768px) {
    padding: var(--space-xl);
  }
}

.boxHeading {
  max-width: 1177px;
  margin: 0 auto;
  font-size: var(--fs-large);
  line-height: 1.2;
  letter-spacing: 0.01em;
  text-align: center;
  padding: var(--space-md) 0;
  position: relative;
  // z-index: 10;

  // @media (min-width: 1024px) {
  //   font-size: var(--fs-xxlarge);
  // }
}

.arrowContainer {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: -40px;
  background-color: #141515;
  border-radius: 50%;
  // z-index: 50;

  @media (min-width: 768px) {
    bottom: -60px;
  }
}

.arrow {
  width: 40px;
  height: 40px;
  background-color: #141515;
  border-radius: 50%;
  cursor: pointer;

  @media (min-width: 768px) {
    width: 62px;
    height: 62px;
  }
}

.textContent {
  color: white;
  text-align: center;
  max-width: 950px;
  margin: var(--space-xl) auto var(--space-lg);
  padding: 0 var(--space-sm);
  @media (min-width: 768px) {
    margin: 63px auto 0;
  }
}

.textParagraph {
  font-size: var(--fs-medium);
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.04em;
  padding-bottom: var(--space-sm);
  text-align: center;
}

.largeTextContent {
  color: white;
  text-align: center;
  max-width: 950px;
  margin: 0 auto 50px;
  padding: 0 var(--space-sm);
  // @media (min-width: 768px) {
  //   margin: 0 auto 100px;
  // }
}

.subheading {
  font-size: var(--fs-medium);
  padding-bottom: var(--space-sm);
  text-align: center;
  margin:1rem;
}

.largeTextParagraph {
  font-size: var(--fs-medium);
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.04em;
  padding-bottom: var(--space-sm);
  text-align: center;
}

.dashedBox {
  max-width: 1148px;
  margin: 0 var(--space-sm) var(--space-lg);
  border: 3px dashed #FF5E5E;
  border-radius: 33px;
  padding: var(--space-md);
  text-align: center;

  @media (min-width: 768px) {
    border-width: 6px;
    padding: var(--space-lg);
    margin-bottom: 122px;
  }
}

.dashedBoxHeading {
  font-size: var(--fs-medium);
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.01em;
  text-align: center;
  color: #FFFFFF;
  max-width: 600px;
  margin: var(--space-md) auto;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--space-md) 0;

  @media (min-width: 768px) {
    margin: var(--space-lg) 0;
  }
}

.gradientButton {
  background: linear-gradient(90deg, #1B6F22 19.8%, #30C43C 47.38%, #28A432 73.15%);
  width: 100%;
  height: clamp(70px, 15vw, 92px);
  border-radius: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  @media (min-width: 768px) {
    width: clamp(300px, 50vw, 514.8px);
  }
}

.buttonText {
    color: white;
    font-weight: bold;
    font-size: var(--fs-large);
    line-height: 1.2;
    text-align: center;
    margin-right: 2rem;
    background-color: transparent;
    border: none;
}

.daysCounter {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  border: 1px solid black;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50%;

  // @media (min-width: 768px) {
  //   height: 130px;
  //   width: 133px;
  // }
}

.daysNumber {
  font-size: var(--fs-medium);
  font-weight: 600;
  margin-top: -0.5rem;
}

.daysText {
  font-size: var(--fs-small);
  line-height: 5px;
  letter-spacing: 0.01em;
  text-align: center;
  font-weight: bold;
}

.dashedBoxSubheading {
  font-size: var(--fs-medium);
  line-height: 1.2;
  letter-spacing: 0.01em;
  text-align: center;
  font-weight: normal;
  max-width: 850px;
  margin: 0 auto var(--space-md);
}

.startButton {
  width: 100%;
  height: 60px;
  font-weight: bold;
  font-size: var(--fs-large);
  line-height: 1.2;
  letter-spacing: 0.04em;
  text-align: center;
  background-color: #FF4747;
  border-radius: 0.75rem;
  margin-bottom: var(--space-md);
  color: white;

  @media (min-width: 768px) {
    height: 129px;
    margin-bottom: var(--space-lg);
  }
}

.dashedBoxParagraph {
  font-size: var(--fs-medium);
  line-height: 1.2;
  letter-spacing: 0.01em;
  font-weight: normal;
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
}

.secretSection {
  max-width: 995px;
  margin: 0 auto var(--space-lg);
  text-align: center;
  padding: 0 var(--space-sm);
  @media (min-width: 768px) {
    margin-bottom: 133px;
  }
}

.secretHeading {
  font-size: var(--fs-large);
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: 0.04em;
  text-align: center;
}

.secretSubheading {
  font-size: var(--fs-medium);
  line-height: 1.2;
  font-weight: normal;
  letter-spacing: 0.04em;
  text-align: center;
  max-width: 850px;
  margin: var(--space-sm) auto 0;

  @media (min-width: 768px) {
    margin-top: var(--space-md);
  }
}

.imageGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.25rem;
  max-width: 1465px;
  margin: 0 auto;
  padding: 0 var(--space-sm);
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

.gridImage {
  width: 100%;
  max-height:125px;
  height: 100%;
  background-color: white;
}

.finalSection {
  max-width: 995px;
  margin: var(--space-md) auto 0;
  text-align: center;

  @media (min-width: 768px) {
    margin-top: 5.4375rem;
  }
}

.finalParagraph {
  font-size: var(--fs-medium);
  line-height: 1.2;
  letter-spacing: 0.04em;
  font-weight: bold;
  max-width: 850px;
  margin: var(--space-sm) auto 0;
  padding: 0 var(--space-sm);
  @media (min-width: 768px) {
    margin-bottom: var(--space-lg);
  }
}

.h4FianlParagraph {
  margin-bottom: 0 auto var(--space-lg);
}

.highlightedParagraph {
  color: #FF4747;
  font-size: var(--fs-large);
  line-height: 1.2;
  letter-spacing: 0.04em;
  font-weight: bold;
  padding: var(--space-lg) 0 var(--space-sm);
  // @media (min-width: 768px) {
  //   font-size: var(--fs-xlarge);
  // }
}

.videoContainer {
  margin: var(--space-md) auto;
  width: 100%;
  max-width: min(350px, 70vw); // Limit the width on larger screens
  aspect-ratio: 9 / 16; // Set the aspect ratio to 9:16 for vertical video
  
  @media (min-width: 768px) {
    margin: var(--space-lg) auto;
  }
}

.video {
  display: block;
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: contain;
  border-radius: 8px;
}