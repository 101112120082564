Box sizing rules *,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
--fs-small: clamp(0.8rem, 2vw, 1.25rem);
  --fs-medium: clamp(0.9rem, 3vw, 1.5rem);
  --fs-large: clamp(1.5rem, 5vw, 2.5rem);
  /* --fs-xlarge: clamp(1.2rem, 2vw, 3.125rem); */
  /* --fs-xxlarge: clamp(1.6rem, 5vw, 4.375rem); */
  
  --space-xs: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
  --space-sm: clamp(1rem, 0.9rem + 0.5vw, 1.5rem);
  --space-md: clamp(1.5rem, 1.3rem + 1vw, 2.5rem);
  --space-lg: clamp(2rem, 5vw, 6rem);
  --space-xl: clamp(3rem, 2.8rem + 1.5vw, 5rem);
}

.App {
  min-height:100svh;
  height:100vh;
  height:100svh;
  flex: 1;
  /* user-select: none; */
  background-color: black;
}

.direction-ltr{
  direction: ltr !important;
}

.text-ltr{
  text-align: left !important;
}

button{
  cursor: pointer;
}
/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body,
#root {
  /* min-height: 100%; */
  overflow-y: hidden;
  /* scroll-behavior: smooth; */
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: 'Rubik';
  background-color: black;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    /* scroll-behavior: auto !important; */
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  display:none;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6a6a6aaa;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .ProjectContainer {
  width: 100%;
  min-height:calc(100vh);
  min-height: calc(100svh);
  height:calc(100svh);
} */

/* @media (max-width: 763px) {
  .ProjectContainer {
    width: 100%;
  }
} */
