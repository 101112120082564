@font-face {
  font-family: "Poppins";
  src: url("/public/fonts/Poppins/Poppins-Bold.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("/public/fonts/Poppins/Poppins-Medium.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/fonts/Roboto/Roboto-Black.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("/public/fonts/Roboto/Roboto-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("/public/fonts/Roboto/Roboto-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("/public/fonts/Roboto/Roboto-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Rubik";
  src: url("/public/fonts/Rubik/Rubik-Medium.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  src: url("/public/fonts/Rubik/static/Rubik-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  src: url("/public/fonts/Rubik/static/Rubik-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  src: url("/public/fonts/Rubik/static/Rubik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  src: url("/public/fonts/Rubik/static/Rubik-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
.HebFont * {
  font-family: Rubik !important;
}

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap");
