.landingPage {
  display: grid;
  width: 100vw;
  grid-template-columns: 1fr;
  overflow-y: auto;
  overflow-x: hidden;
  height: 94dvh;
}

.section {
  // padding: 4rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // min-height: 800px;
// height: min(800px, 100vh);
}

.evenSection {
  background-color: #181818;
}

.oddSection {
  background-color: black;
}

// // Add responsive styles
// @media (max-width: 768px) {
//   .section {
//     padding: 3rem 1rem;
//   }
// }
