:root {
    --header: clamp(1.8rem,4vw,6rem);
    --headerLineHeight: clamp(2.5rem,4vw,6rem);
    --defaultPurple: #4F46E5;
    --defaultTurquoise: #0D9488;
    --defaultPink: #E546A1;
    --defaultOrange: #ffa500;
    --highlightPurple: #6358FF;
    --highlightTurquoise: #10B9AA;
    --highlightPink: #FF58B2;
    --highlightOrange: #ffb833;
    --brandPurple: #B398EF;
    --brandPurpleHover: #997DE0;
    --brandBlue: #3CCBDA;
}