.switchWordsContainer {
  display: inline-block;
  // position: relative;
  overflow: hidden;
  vertical-align: bottom;
  height: 1.3em;
  margin: 0 0.1em; // Add minimal horizontal margin
}

.word {
  display: inline-block;
  // position: relative;
  // left: 0;
  // right: 0;
  white-space: nowrap;
}