// .blackSection {
//     background-color: black;
//     color: white;
//     padding: 100px 0 150px 0;
//   }
  
//   .container {
//     max-width: 1440px;
//     margin: 0 auto;
//     padding: 5rem 0;
//   }
  
//   .mainTitle, .sectionTitle {
//     font-size: 30px;
//     line-height: 45px;
//     letter-spacing: 0.01em;
//     font-weight: bold;
//     text-align: center;
//     max-width: 1279px;
//     margin: 0 auto;
  
//     @media (min-width: 768px) {
//       font-size: 75px;
//       line-height: 95px;
//     }
//   }
  
//   .sliderContainer {
//     max-width: 1300px;
//     margin: 40px auto 0;
//     padding: 0 40px;
  
//     @media (min-width: 768px) {
//       padding: 0 112px;
//     }
  
//     @media (min-width: 1024px) {
//       padding: 0 40px;
//     }
//   }
  
//   .sliderItem {
//     padding: 0 16px;
//     margin: 20px 0;
//   }
  
//   .reviewImage {
//     width: 100%;
//     height: 170px;
//     object-fit: cover;
//   }
  
//   .nextArrow, .prevArrow {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     z-index: 10;
//     cursor: pointer;
//   }
  
//   .nextArrow {
//     right: -50px;
//   }
  
//   .prevArrow {
//     left: -50px;
//   }
  
//   .arrowIcon {
//     font-size: 50px;
//   }
  
//   .whiteSection {
//     direction: rtl;
//     background-color: white;
//     color: black;
//     padding: 40px 16px;
//     // max-width: 1440px;
//     // margin: 0 auto;
  
//     @media (min-width: 768px) {
//       padding: 100px 16px;
//     }
//   }
//   .innerContent{
//     max-width: 1440px;
//     margin: 0 auto;
   
// }
  
//   .subTitle {
//     direction: rtl;
//     font-size: 24px;
//     line-height: normal;
//     letter-spacing: 0.01em;
//     text-align: center;
//     font-weight: normal;
//     max-width: 900px;
//     margin: 0 auto;
  
//     @media (min-width: 768px) {
//       font-size: 40px;
//       line-height: 60px;
//     }
  
//     @media (min-width: 1024px) {
//       line-height: 95px;
//     }
//   }
  
//   .redTitle {
//     direction: rtl;
//     color: #FF4747;
//     font-size: 36px;
//     line-height: normal;
//     letter-spacing: 0.01em;
//     font-weight: bold;
//     text-align: center;
//     padding: 20px 0;
  
//     @media (min-width: 768px) {
//       font-size: 61px;
//       padding: 40px 0;
//     }
//   }
  
//   .benefitsList, .priceList {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-end;
//     padding: 32px 0;
//     font-size: 18px;
//     line-height: 40px;
//     letter-spacing: 0.01em;
//     text-align: right;
//     direction: ltr;
  
//     @media (min-width: 768px) {
//       font-size: 30px;
//       line-height: 80px;
//     }
  
//     @media (min-width: 1024px) {
//       font-size: 40px;
//       line-height: 95px;
//     }
  
//     li {
//       display: flex;
//       gap: 8px;
//       direction: rtl;
//     }
//   }
  
//   .dotIcon {
//     margin-top: 8px;
//     min-width: 25px;
  
//     @media (min-width: 768px) {
//       margin-top: 28px;
//       min-width: 50px;
//     }
//   }
  
//   .bottomText {
//     direction: rtl;
//     font-size: 24px;
//     line-height: normal;
//     letter-spacing: 0.01em;
//     text-align: center;
//     margin-top: 40px;
  
//     @media (min-width: 768px) {
//       font-size: 40px;
//       line-height: 95px;
//     }
//   }
  
//   .boldText {
//     font-size: 14px;
//     line-height: 30px;
//     font-weight: bold;
//     text-align: right;
  
//     @media (min-width: 768px) {
//       font-size: 30px;
//       line-height: 80px;
//     }
  
//     @media (min-width: 1024px) {
//       font-size: 40px;
//       line-height: 95px;
//     }
//   }
  
//   .centerText {
//     font-size: 14px;
//     line-height: 30px;
//     text-align: center;
//     margin-top: 8px;
  
//     @media (min-width: 768px) {
//       font-size: 30px;
//       line-height: 80px;
//       margin-top: 105px;
//     }
  
//     @media (min-width: 1024px) {
//       font-size: 40px;
//       line-height: 95px;
//     }
//   }

//   .priceTitle {
//     font-size: 25px;
//     line-height: 35px;
//     text-align: center;
//     font-weight: bold;
//     margin: 16px 0;
  
//     @media (min-width: 768px) {
//       font-size: 45px;
//       line-height: 80px;
//     }
  
//     @media (min-width: 1024px) {
//       font-size: 55px;
//       line-height: 95px;
//     }
//   }
  
//   .priceComparison {
//     display: flex;
//     justify-content: center;
//     font-size: 23px;
//     line-height: 35px;
//     font-weight: normal;
//     text-align: center;
//     margin-top: 8px;
//     direction: ltr;
  
//     @media (min-width: 768px) {
//       font-size: 60px;
//       line-height: 85px;
//     }
  
//     @media (min-width: 1024px) {
//       font-size: 80px;
//       line-height: 95px;
//     }
  
//     h4 {
//       margin: 0 8px;
//     }
//   }
  
//   .currency {
//     font-size: 20px;
//     vertical-align: top;
  
//     @media (min-width: 768px) {
//       font-size: 35px;
//       margin-top: 8px;
//     }
//   }
  
//   .finalPrice {
//     font-size: 30px;
//     line-height: 55px;
//     text-align: center;
//     margin-top: 8px;
//     margin-bottom: .5rem;
  
//     @media (min-width: 768px) {
//       font-size: 70px;
//       line-height: 95px;
//     }
  
//     .currency {
//       font-size: 25px;
//       margin-left: 8px;
  
//       @media (min-width: 768px) {
//         font-size: 40px;
//       }
//     }
  
//     span:last-child {
//       font-size: 40px;
  
//       @media (min-width: 768px) {
//         font-size: 100px;
//       }
//     }
//   }
  
//   .ctaContainer {
//     max-width: 1015px;
//     margin: 0 auto;
//     border: 6px dashed #FF5E5E;
//     border-radius: 33px;
//     padding: 12px;
  
//     @media (min-width: 640px) {
//       padding: 20px;
//     }
  
//     @media (min-width: 768px) {
//       padding: 40px;
//     }
//   }
  
//   .ctaTitle {
//     font-size: 40px;
//     line-height: 60px;
//     font-weight: bold;
//     letter-spacing: 0.01em;
//     text-align: center;
//     color: #FFFFFF;
  
//     @media (min-width: 768px) {
//       font-size: 60px;
//       line-height: 95px;
//     }
//   }
  
//   .ctaText {
//     font-size: 30px;
//     line-height: 40px;
//     text-align: center;
//     font-weight: normal;
//     margin-top: 16px;
  
//     @media (min-width: 768px) {
//       font-size: 40px;
//       line-height: 60px;
//       margin-top: 24px;
//     }
//   }
  
//   .ctaButtons {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: space-between;
//     max-width: 95%;
//     gap: 30px;
//     margin: 16px auto;
  
//     @media (min-width: 768px) {
//       flex-direction: row;
//       margin: 70px auto;
//     }
//   }
  
//   .arrowDown {
//     width: 50px;
//     height: 83px;
//     margin: 0 auto;
  
//     @media (min-width: 768px) {
//       margin: 0;
//     }
//   }
  
//   .trialButton {
//     background: linear-gradient(90deg, #1B6F22 19.8%, #30C43C 47.38%, #28A432 73.15%);
//     width: 100%;
//     height: 90px;
//     border-radius: 90px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: relative;
  
//     @media (min-width: 768px) {
//       width: 514.8px;
//       height: 128px;
//     }
//   }
  
//   .trialText {
//     font-weight: bold;
//     font-size: 24px;
//     line-height: 1.2;
//     letter-spacing: 0.01em;
//     text-align: center;
//     margin-right: 32px;
//     color: white;
//     background-color: transparent;
//     border: none;
  
//     @media (min-width: 768px) {
//       font-size: 48px;
//       line-height: 95px;
//     }
//   }
  
//   .trialDays {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     position: absolute;
//     right: 0;
//     border: 1px solid black;
//     height: 90px;
//     width: 90px;
//     border-radius: 50%;
  
//     @media (min-width: 768px) {
//       height: 130px;
//       width: 133px;
//     }
  
//     h2 {
//       font-size: 24px;
//       font-weight: 600;
//       margin-top: -8px;
  
//       @media (min-width: 768px) {
//         font-size: 50px;
//         margin-top: -16px;
//       }
//     }
  
//     h4 {
//       font-size: 16px;
//       line-height: 5px;
//       letter-spacing: 0.01em;
//       text-align: center;
//       font-weight: bold;
  
//       @media (min-width: 768px) {
//         font-size: 30px;
//       }
//     }
//   }
  
//   .startButton {
//     width: 100%;
//     height: 70px;
//     font-weight: bold;
//     font-size: 20px;
//     line-height: 60px;
//     letter-spacing: 0.04em;
//     text-align: center;
//     background-color: #FF4747;
//     border-radius: 12px;
//     margin-bottom: 8px;
//     color: white;
  
//     @media (min-width: 768px) {
//       height: 115px;
//       font-size: 36px;
//       line-height: 82px;
//       margin-bottom: 40px;
//     }
//   }
  
//   .paymentCards {
//     display: block;
//     max-width: 400px;
//     margin: 20px auto;
//   }
  
//   .disclaimer {
//     font-size: 15px;
//     line-height: 30px;
//     letter-spacing: 0.01em;
//     font-weight: normal;
//     text-align: center;
//     margin-top: 8px;
  
//     @media (min-width: 768px) {
//       font-size: 25px;
//       line-height: 55px;
//     }
//   }
  
//   .finalText {
//     font-size: 20px;
//     line-height: 30px;
//     letter-spacing: 0.01em;
//     font-weight: normal;
//     max-width: 95%;
//     margin: 16px auto;
//     text-align: center;
  
//     @media (min-width: 768px) {
//       font-size: 30px;
//       line-height: 55px;
//     }
//   }

.blackSection {
  background-color: black;
  color: white;
  padding: var(--space-xl) var(--space-sm);
  &.ltr{
    direction: ltr;
  }
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: var(--space-lg) var(--space-sm);
}

.mainTitle, .sectionTitle {
  font-size: var(--fs-large);
  line-height: 1.3;
  letter-spacing: 0.01em;
  font-weight: bold;
  text-align: center;
  max-width: 1279px;
  margin: 0 auto;
}

.sliderContainer {
  max-width: 1300px;
  margin: var(--space-md) auto 0;
  padding: 0 var(--space-sm);
}

.sliderItem {
  padding: 0 var(--space-xs);
  margin: var(--space-sm) 0;
}

.reviewImage {
  width: 100%;
  height: clamp(120px, 20vw, 170px);
  object-fit: cover;
}

.nextArrow, .prevArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}

.nextArrow {
  right: -25px;
}

.prevArrow {
  left: -25px;
}

.arrowIcon {
  font-size: clamp(30px, 5vw, 50px);
}

.whiteSection {
  direction: rtl;
  background-color: white;
  color: black;
  padding: var(--space-lg) var(--space-sm);
  display:flex;
  flex-direction: column;
  align-items: center;
  &.ltr{
    direction: ltr;
  }
}

.innerContent {
  max-width: 1440px;
  margin: 0 auto;
}

.subTitle {
  font-size: var(--fs-medium);
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: center;
  font-weight: normal;
  max-width: 900px;
  margin: 0 auto;
}

.redTitle {
  color: #FF4747;
  font-size: var(--fs-large);
  line-height: 1.3;
  letter-spacing: 0.01em;
  font-weight: bold;
  text-align: center;
  padding: var(--space-md) 0;
}


.benefitsList, .priceList {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--space-md) 0;
  font-size: var(--fs-medium);
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: right;
  direction: ltr;
  margin: var(--space-md) 0 !important;
  &.ltr{
    direction: rtl;
    >*{
      direction: ltr;
      text-align: left;
    }
  }
  li {
    display: flex;
    gap: var(--space-xs);
    direction: rtl;
  }
}

.dotIcon {
  margin-top: 0.5em;
  min-width: clamp(20px, 3vw, 50px);
}

.bottomText {
  font-size: var(--fs-medium);
  line-height: 1.3;
  letter-spacing: 0.01em;
  text-align: center;
  margin-top: var(--space-md);
}

.boldText, .centerText {
  font-size: var(--fs-medium);
  line-height: 1.5;
text-align: right;
  margin-top: var(--space-sm);
  &.ltr{
    direction: ltr;
    text-align: left;
  }
}

.centerText {
  font-size: var(--fs-medium);
  line-height: 1.5;
  text-align: right;
  &.ltr{
    direction: ltr;
    text-align: center;
  }
}

.priceTitle {
  font-size: var(--fs-large);
  line-height: 1.3;
  text-align: center;
  font-weight: bold;
  margin: var(--space-sm) 0;
}

.priceComparison {
  display: flex;
  justify-content: center;
  font-size: var(--fs-medium);
  line-height: 1.3;
  font-weight: normal;
  text-align: center;
  margin-top: var(--space-sm);
  direction: ltr;

  h4 {
    margin: 0 var(--space-xs);
  }
}

.currency {
  // font-size: 0.6em;
  vertical-align: top;
}

.finalPrice {
  font-size: var(--fs-medium);
  line-height: 1.3;
  text-align: center;
  margin-top: var(--space-sm);
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .currency {
    font-size: 0.6em;
    margin-left: var(--space-xs);
  }

  span:last-child {
    font-size: 1.2em;
  }
}

.ctaContainer {
  max-width: 1015px;
  margin: 0 auto;
  border: 6px dashed #FF5E5E;
  border-radius: 33px;
  padding: var(--space-md);
}

.ctaTitle {
  font-size: var(--fs-large);
  line-height: 1.3;
  font-weight: bold;
  letter-spacing: 0.01em;
  text-align: center;
  color: #FFFFFF;
}

.ctaText {
  font-size: var(--fs-medium);
  line-height: 1.5;
  text-align: center;
  font-weight: normal;
  margin-top: var(--space-sm);
}

.ctaButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 95%;
  gap: var(--space-md);
  margin: var(--space-lg) auto;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.arrowDown {
  width: clamp(30px, 5vw, 50px);
  height: auto;
  margin: 0 auto;
}

.trialButton {
  background: linear-gradient(90deg, #1B6F22 19.8%, #30C43C 47.38%, #28A432 73.15%);
  width: 100%;
  height: clamp(70px, 15vw, 128px);
  border-radius: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  @media (min-width: 768px) {
    width: clamp(300px, 50vw, 514.8px);
  }
}

.trialText {
  font-weight: bold;
  font-size: var(--fs-large);
  line-height: 1.2;
  letter-spacing: 0.01em;
  text-align: center;
  margin-right: var(--space-md);
  color: white;
  background-color: transparent;
  border: none;
}

.trialDays {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  border: 1px solid black;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50%;

  
  // h2 {
  //   font-size: clamp(20px, 4vw, 50px);
  //   font-weight: 600;
  //   margin-top: -0.3em;
  // }
  
  // h4 {
  //   font-size: clamp(14px, 2vw, 30px);
  //   line-height: 1;
  //   letter-spacing: 0.01em;
  //   text-align: center;
  //   font-weight: bold;
  // }
  .trialNumber {
    font-size: var(--fs-medium);
    font-weight: 600;
    margin-top: -0.5rem;
  }
  
  .trialDaysText {
    font-size: var(--fs-small);
    line-height: 5px;
    text-align: center;
    font-weight: bold;
  }
}

.startButton {
  width: 100%;
  height: clamp(60px, 10vw, 115px);
  font-weight: bold;
  font-size: var(--fs-large);
  line-height: 1.5;
  letter-spacing: 0.04em;
  text-align: center;
  background-color: #FF4747;
  border-radius: 12px;
  margin-bottom: var(--space-sm);
  color: white;
}

.paymentCards {
  display: block;
  max-width: min(100%, 400px);
  margin: var(--space-md) auto;
}

.disclaimer {
  font-size: var(--fs-small);
  line-height: 1.5;
  letter-spacing: 0.01em;
  font-weight: normal;
  text-align: center;
  margin-top: var(--space-sm);
}

.finalText {
  font-size: var(--fs-medium);
  line-height: 1.5;
  letter-spacing: 0.01em;
  font-weight: normal;
  max-width: 95%;
  margin: var(--space-md) auto;
  text-align: center;
}

.carouselContainer {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 var(--space-sm);
}

.mainTitle {
  font-size: var(--fs-large);
  line-height: 1.3;
  letter-spacing: 0.01em;
  font-weight: bold;
  text-align: center;
  margin-bottom: var(--space-md);
}

.sliderContainer {
  margin: var(--space-md) auto 0;
  
  .slick-list {
    margin: 0 -10px;
  }
  
  .slick-slide > div {
    padding: 0 10px;
  }

  .slick-dots {
    bottom: -40px;

    li button:before {
      color: white;
    }

    li.slick-active button:before {
      color: white;
    }
  }
}

.sliderItem {
  padding: var(--space-xs);
}

.reviewImage {
  width: 100%;
  height: clamp(120px, 20vw, 170px);
  object-fit: cover;
  border-radius: 8px;
}

.nextArrow,
.prevArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.nextArrow {
  right: -50px;

  @media (max-width: 1400px) {
    right: -20px;
  }
}

.prevArrow {
  left: -50px;

  @media (max-width: 1400px) {
    left: -20px;
  }
}

.arrowIcon {
  font-size: 24px;
  color: white;
}

@media (max-width: 600px) {
  .nextArrow,
  .prevArrow {
    display: none;
  }
}