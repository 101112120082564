.lpFeatureHero {
  color: #ffffff;
  max-width: 1240px;
  display: grid;
  width:100%;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  // gap: 20px;
  min-height: 750px;
  padding: 1rem;
  font-family: Poppins, sans-serif;
  
  @media (max-width: 768px) {
  grid-template-columns:1fr;
  min-height: 94dvh;
  }

  .imageSection{
    display:flex;
    justify-content: center;
    align-items: center;
    .purpleFrame{
      background-color: var(--brandPurple);
      width: min(450px, 60vw);
      aspect-ratio: 16/9;
      // height: 250px;
      border-radius: 20px;
      transform: rotate(15deg);
      display: flex;
      justify-content: center;
      align-items: center;
      padding:1rem;
      // box-shadow: 30px 20px 30px rgba(#B398EF, 100);
      >img{
        aspect-ratio: 16/9;
        border-radius: 20px;
      }
      &:hover{
        transform: rotate(0deg);
        box-shadow: 0 0 100px 3px rgba(#B398EF, 0.5);
      }
      transition: all 0.3s ease-in-out;
    }
  }
  .contentSection{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      justify-content: flex-start;
      height: fit-content;
    }
    .textContentWrapper{
      display:flex;
      flex-direction: column;
      gap: 3rem;
    }
    .textContent{
      font-size: var(--fs-large);
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 768px) {
        align-items: center;
        font-size: 30px;
      }
        >span{
        line-height: 1;
        font-weight: bold;
      }
    }
  }
}

  .imageSection {
    .purpleFrame {
      box-shadow: 0 0 0 rgba(var(--brandPurple-rgb), 0);
      transition: all 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

      &:hover {
        box-shadow: 0 0 30px rgba(var(--brandPurple-rgb), 0.5);
      }
    }
  }

// .content {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   max-width: 1200px;
//   margin: 0 auto;
//   padding: 0 20px;
// }

// .textContent {
//   flex: 1;
//   max-width: 50%;
// }

// .featureTitle {
//   font-size: 18px;
//   text-transform: uppercase;
//   color: #a78bfa;
//   margin-bottom: 10px;
// }

// .mainTitle {
//   font-size: 48px;
//   font-weight: bold;
//   margin-bottom: 20px;
// }

// .description {
//   font-size: 18px;
//   margin-bottom: 30px;
//   line-height: 1.5;
// }

// .imageWrapper {
//   flex: 1;
//   display: flex;
//   justify-content: flex-end;
// }

// .featureImage {
//   max-width: 100%;
//   height: auto;
//   border-radius: 16px;
//   box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
// }

// @media (max-width: 768px) {
//   .content {
//     flex-direction: column;
//     text-align: center;
//   }

//   .textContent {
//     max-width: 100%;
//     margin-bottom: 40px;
//   }

//   .imageWrapper {
//     justify-content: center;
//   }
// }