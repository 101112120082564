.thankYouPageMainContainer{
    width:100%;
    height: 100%;
    background-color: white;
    display:flex;
    align-items: flex-start;
    justify-content: center;
    padding: 5% 0 ;
    border: 1px solid white;


    .thankYouPageDataContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        font-family: Poppins, sans-serif;
        height: 90%;
        width: 90vw;
        max-width: 1050px;
        >img{
            width: 15%;
            max-width:150px;
        }
        >h1{
           font-size: clamp(2rem, 4vw, 80px); 
           letter-spacing: 8px;
           white-space: nowrap;
        }
        >p{
            max-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: clamp(0.8rem, 2.5vw, 28px); 
            font-weight: 500;
            letter-spacing: 2.8px;
            // white-space: nowrap;
            text-align: center;
        }

        >section{
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            align-self: flex-start;
            justify-content: center;
            gap: 1rem;
           
            >span {
                >a{
                 all:unset;
                 letter-spacing: 2.8px;
                 font-size: clamp(0.8rem, 4vw, 28px);
                 cursor: pointer;
                 &:hover{
                    text-decoration: underline rgba(0, 0, 255, 0.899);
                 }
                }
            }
        }
    }

    .thankYouPageButton {
        background-color: var(--defaultTurquoise);
        color: #fff;
        border: none;
        padding: 0.5rem;
        border-radius: 10px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        flex-grow: 1;
        width: clamp(250px, 100%, 300px);
        font-size: clamp(0.8rem, 2vw, 1.5rem);
        display: flex;
        align-items: center;
        // justify-content: center;
        gap: 1rem;
        >img{
            flex: 0.3;
            max-width:30px;
            flex-basis: 30%;
        }
        &:hover {
            background-color: var(--highlightTurquoise);
        }

        a {
            flex: 0.7;
            color: inherit;
            text-decoration: none;
            flex-basis: 70%;
        }
    }

    .thankYouPageButtonAlternate {
        @extend .thankYouPageButton;
        background-color: var(--defaultPurple);

        &:hover {
            background-color: var(--highlightPurple);
        }
    }
}
