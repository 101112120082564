.OTPModalBackDrop {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 2;
    backdrop-filter: blur(7px);

    >* {
        box-sizing: border-box;
    }

}

.OTPModalBackDrop .OTPModalContainer {
    position: absolute;
    max-width: 700px;
    min-width: 300px;
    min-height: 15vw;
    max-height: 715px;
    width: 70%;
    background-color: rgb(255, 255, 255);
    color: black;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1.5% 3% 0%;
    // font-family: Poppins;
    box-shadow: 0 0 5px 3px rgb(75 72 72 / 39%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    font-family: sans-serif;
    flex: 1 0 auto;
    direction: ltr !important;

    >p:not(:last-child) {
        margin-top: 1rem;
        font-size: clamp(1rem, 1.5vw, 2rem);
        font-weight: 550;
    }

    >div:last-child {
        margin-bottom: 6%;
    }

    >div:not(:last-child) {
        width: 100%;
        justify-content: center;
        padding-bottom: 6%;



        >input {
            direction: ltr;

            /* Hide the up and down arrows for number input */
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                appearance: none;
                margin: 0; // Optional: You can adjust margin/padding as needed
            }

            border: 1px solid rgba(67, 67, 67, 0.323);
            border-radius: 10px;
            height: 69px;
            min-width: 11%;
            font-size: clamp(1rem, 1vw, 2rem);
            margin: 12% 0 0;

            &:focus-visible,
            :focus,
            :focus-visible,
            :focus-within {
                outline: 1px solid #4F46E5;
            }
        }
    }

    >p:last-child {
        color: red;
        font-size: clamp(1rem, 1vw, 1.2rem);
        margin: 0 0 6%;
        height: 60px;
        line-height: 60px;
    }
}

.ShowModalContainer {
    opacity: 1 !important;
}

@media(max-width:1450px) {
    .OTPModalBackDrop .OTPModalContainer {
        padding: 3.5% 3% 0%;
    }
}

@media (max-width: 1120px) {
    .OTPModalBackDrop .OTPModalContainer {
        >div {
            >input {
                min-width: 14%;
            }
        }
    }
}

@media (max-width:760px) {
    .OTPModalBackDrop .OTPModalContainer {
        padding: 5.5% 3% 0%;
    }
}

@media (max-width:480px) {
    .OTPModalBackDrop .OTPModalContainer {
        padding: 7.5% 3% 0%;
    }
}