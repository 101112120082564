.LetsChatBackDrop {
    // background-color: #ffffff40;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 2;
    backdrop-filter: blur(7px);
    background-color: transparent !important;
}

.LetsChatBackDrop .LetsChatModalContainer {
    position: absolute;
    max-width: 25%;
    min-width: 250px;
    background-color: black;
    color: white;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 6rem 6rem 0;
    font-family: Poppins;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    align-items: center;
    >span:first-child {
        font-size: clamp(2rem, 2vw, 6rem);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.48px;
    }

    >p {
        font-size: clamp(1rem, 1vw, 3rem);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
        border-bottom: 1px solid #A0A2A4;
        padding-bottom: 2rem;
    }

}

.HebClass {
    text-align: right;
}

.ShowChatContainer {
    opacity: 1 !important;
}

.PhoneNumberInput {
    background-color: #ECEFF4;
    color: black;
    border-radius: 7px;
    // padding: 1rem;
    display: flex;
    flex-wrap: nowrap;
    height: 70px;
    width: 100%;
    // justify-content: center;
    font-family: Poppins;
    align-items: center;
    border: 1px solid transparent;

    // >span {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     height: 100%;
    //     // padding: 0 2rem;
    //     width: 30%;
    //     border-right: 1px solid black;

    //     >label {
    //         display: block;
    //         width: 100%;
    //         // height: 100%;
    //         font-size: clamp(0.8rem, 0.8vw, 1.5rem);
    //         font-style: normal;
    //         font-weight: 500;
    //         line-height: normal;
    //         letter-spacing: 2px;
    //         text-transform: uppercase;
    //         // padding-left: 2rem;
    //         // height: 100%;
    //         // padding: 3%;
    //         text-align: center;
    //     }
    // }

    // >input {
    //     width: 70%;
    //     border: none;
    //     outline: none;
    //     height: 90%;
    //     // border-radius: 7px;
    //     padding: 0 1rem;
    //     font-size: clamp(0.7rem, 0.8vw, 3rem);
    //     letter-spacing: 1px;
    // }

    >input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield;
    }

    >input[type=number] {
        -moz-appearance: textfield;
    }
}

.PhoneInput {
    --PhoneInputCountryFlag-height: 25px !important;

    width: 100%;
    border: none;
    outline: none;
    height: 60%;
    // border-radius: 7px;
    padding: 0 1rem;
    font-size: clamp(1rem, 0.8vw, 3rem);
    letter-spacing: 1px;

    >div {
        width: 15%;

        // >select {
        //     width: 30%;
        // }
        >div {
            height: unset;
        }
    }

    >input {
        width: 70%;
        border: none;
        outline: none;
        background-color: transparent;

        &:focus {
            outline: none;
            border: none;
        }
    }

}

.CloseLetsChatModalModalButton {
    position: absolute;
    top: 20px;
    right: 20px;

}

.ValidationErrorMsg {
    color: red;
    text-align: center;
    font-weight: 550;

}

.VisibilityHidden {
    visibility: hidden;
}



@media(max-width: 560px) {
    .LetsChatBackDrop .LetsChatModalContainer {
        padding: 4rem 2rem 0;
        min-width: 250px;
        gap: 1rem;
        top: 54%;
    }
}