.lpVideoSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: #000000;
  color: white;
  padding: 40px 20px;
  font-family: "Poppins", sans-serif;
  @media (max-width: 768px) {
    padding: 20px 15px;
    min-height: 85dvh;
  }
}

.content {
  max-width: 1240px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.header {
  font-size: var(--fs-large);
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  // gap: 0 10px;
  line-height: 1.2;
}

.subHeader{
  font-size: var(--fs-medium);
  text-align: center;
  color: #ffffff;
  // margin-bottom: 30px;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
}

.purpleText {
  color: var(--brandPurple);
  display: inline-flex;
  align-items: center;
}

.videoContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid var(--brandPurple);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.hidden {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.videoPlayer {
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 180px), 1fr));
  gap: 20px;
  
  width: 100%;
  margin-top: 3rem;
  max-width: 800px;
  
  .featureCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    border-radius: 10px;
    padding: 20px 10px;
    width: 100%;
    max-width: 180px;
    height: 200px;
    text-align: center;
    justify-self: center;
    .icon {
      margin-bottom: 5px;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      font-family: "Poppins", sans-serif;
      font-weight: 300;
      .title {
        width: 50%;
      }
      .description {
        font-size: 12px;
        text-align: center;
        width: 90%;
      }
    }
  }
  .index1 {
    background-color: var(--brandPurple);
  }
  
  .index2 {
    background-color: var(--brandBlue);
  }
  
  .index3 {
    background-color: var(--defaultPink);
  }
}

// .placeholderVideo {
  //   width: 100%;
  //   height: 0;
  //   padding-bottom: 56.25%; // 16:9 aspect ratio
  //   background-color: #333333;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
//   color: #ffffff;
//   font-size: 24px;
// }
