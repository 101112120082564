// .container {
//     padding: 100px 0;
//     color: black;
//     background-color: white;
//   }
  
//   .content {
//     padding: 0 1rem;

//   }
  
//   .section {
//     text-align: center;
//     max-width: 1640px;
//     margin: 0 auto;
//   }
  
//   .sectionSecond {
//     margin-top: 100px;
  
//     @media (min-width: 768px) {
//       margin-top: 150px;
//     }
  
//     @media (min-width: 1024px) {
//       margin-top: 200px;
//     }
//   }
  
//   .title {
//     font-size: 40px;
//     line-height: 50px;
//     font-weight: bold;
//     color: black;
//     margin-bottom: 50px;
//     letter-spacing: 0.01em;
  
//     @media (min-width: 768px) {
//       font-size: 60px;
//       line-height: 70px;
//       margin-bottom: 75px;
//     }
  
//     @media (min-width: 1024px) {
//       font-size: 90px;
//       line-height: 95px;
//       margin-bottom: 100px;
//     }
//   }
  
//   .redText {
//     color: #E21F1F;
//   }
  
//   .greenText {
//     color: #26AD33;
//   }
  
//   .list {
//     text-align: right;
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     align-items: flex-end;
//     justify-content: flex-end;
//     gap: 1rem;
//     font-size: 30px;
//     line-height: 40px;
//     letter-spacing: 0.01em;
//     direction: ltr;
  
//     @media (min-width: 768px) {
//       gap: 1.5rem;
//       font-size: 45px;
//       line-height: 55px;
//     }
  
//     @media (min-width: 1024px) {
//       gap: 2.5rem;
//       font-size: 60px;
//       line-height: 70px;
//     }
//   }
  
//   .listItem {
//     display: flex;
//     align-items: flex-start;
//     gap: 0.5rem;
//   }
  
//   .crossIcon {
//     color: #FF0000;
//     margin-top: 0.5rem;
//     min-width: 25px;
  
//     @media (min-width: 768px) {
//       margin-top: 0.5rem;
//       min-width: 50px;
//     }
  
//     @media (min-width: 1024px) {
//       margin-top: 0.75rem;
//     }
//   }
  
//   .checkIcon {
//     color: #26AD33;
//     margin-top: 0.25rem;
//     font-size: 40px;
  
//     @media (min-width: 768px) {
//       margin-top: 0.75rem;
//       font-size: 50px;
//     }
  
//     @media (min-width: 1024px) {
//       margin-top: 1.25rem;
//     }
//   }
  
//   .boxTitle {
//     font-size: 40px;
//     line-height: 50px;
//     font-weight: bold;
//     text-align: center;
//     margin-bottom: 2rem;
//     max-width: 1274px;
//     margin-left: auto;
//     margin-right: auto;
//     margin-top: 100px;
  
//     @media (min-width: 768px) {
//       font-size: 50px;
//       line-height: 60px;
//       margin-bottom: 3rem;
//       margin-top: 150px;
//     }
  
//     @media (min-width: 1024px) {
//       font-size: 70px;
//       line-height: 95px;
//       margin-bottom: 4rem;
//       margin-top: 200px;
//     }
//   }
  
//   .boxDescription {
//     direction: rtl;
//     font-size: 30px;
//     line-height: 40px;
//     text-align: center;
//     font-weight: normal;
//     max-width: 1274px;
//     margin-left: auto;
//     margin-right: auto;
  
//     @media (min-width: 768px) {
//       font-size: 40px;
//       line-height: 50px;
//     }
  
//     @media (min-width: 1024px) {
//       font-size: 70px;
//       line-height: 95px;
//       padding: 0 5rem;
//     }
//   }
  
//   .box {
//     direction: rtl;
//     max-width: 1173px;
//     margin: 103px auto 0;
//     border: 6px dashed #FF5E5E;
//     border-radius: 33px;
//     padding: 1.25rem;
  
//     @media (min-width: 768px) {
//       padding: 2.5rem;
//     }
//   }
  
//   .boxHeading {
//     font-size: 40px;
//     font-weight: bold;
//     line-height: 60px;
//     text-align: center;
//     color: black;
  
//     @media (min-width: 768px) {
//       font-size: 60px;
//       line-height: 95px;
//     }
//   }
  
//   .boxParagraph {
//     font-size: 30px;
//     line-height: 40px;
//     text-align: center;
//     font-weight: normal;
//     margin-top: 1rem;
//     max-width: 850px;
//     margin-left: auto;
//     margin-right: auto;
  
//     @media (min-width: 768px) {
//       font-size: 40px;
//       line-height: 60px;
//       margin-top: 1.5rem;
//     }
//   }
  
//   .buttonContainer {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     gap: 1.25rem;
//     max-width: 95%;
//     margin: 1rem auto;
  
//     @media (min-width: 768px) {
//       flex-direction: row;
//       gap: 6rem;
//       margin: 70px auto;
//     }
//   }
  
//   .arrowIcon {
//     width: 50px;
//     height: 83px;
//     margin: 0 auto;
  
//     @media (min-width: 768px) {
//       margin: 0;
//     }
//   }
  
//   .gradientButton {
//     background: linear-gradient(90deg, #1B6F22 19.8%, #30C43C 47.38%, #28A432 73.15%);
//     width: 100%;
//     height: 90px;
//     border-radius: 90px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: relative;
  
//     @media (min-width: 768px) {
//       width: 514.8px;
//       height: 130px;
//     }
//   }
  
//   .buttonText {
//     color: white;
//     font-weight: bold;
//     font-size: 24px;
//     line-height: 1.2;
//     text-align: center;
//     margin-right: 2rem;
//     background-color: transparent;
//     border: none;
  
//     @media (min-width: 768px) {
//       font-size: 48px;
//       line-height: 95px;
//     }
//   }
  
//   .trialDays {
//     color: white;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     position: absolute;
//     right: 0;
//     border: 1px solid black;
//     height: 90px;
//     width: 90px;
//     border-radius: 50%;
  
//     @media (min-width: 768px) {
//       height: 130px;
//       width: 133px;
//     }
//   }
  
//   .trialNumber {
//     font-size: 24px;
//     font-weight: 600;
//     margin-top: -0.5rem;
  
//     @media (min-width: 768px) {
//       font-size: 50px;
//       margin-top: -1rem;
//     }
//   }
  
//   .trialText {
//     font-size: 16px;
//     line-height: 5px;
//     text-align: center;
//     font-weight: bold;
  
//     @media (min-width: 768px) {
//       font-size: 30px;
//     }
//   }
  
//   .startButton {
//     color: white;
//     border-radius: 0.75rem;
//     width: 100%;
//     height: 70px;
//     font-weight: bold;
//     font-size: 20px;
//     line-height: 60px;
//     text-align: center;
//     background-color: #FF4747;
//     margin-bottom: 0.5rem;
  
//     @media (min-width: 768px) {
//       height: 115px;
//       font-size: 36px;
//       line-height: 82px;
//       margin-bottom: 40px;
//     }
//   }
  
//   .cardsImage {
//     display: block;
//     max-width: 400px;
//     margin: 1.5rem auto;
  
//     @media (min-width: 768px) {
//       margin: 1.5rem auto;
//     }
//   }

.suitableComponent {
  font-family: 'Rubik', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

p, span, div {
  font-weight: 400;
}

.container {
  padding: var(--space-xl) 0;
  color: black;
  background-color: white;
  &.ltr{
    direction: ltr;
  }
}

.content {
  padding: 0 var(--space-xs);
}

.section {
  width:65%;
  text-align: center;
  max-width: 1640px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.ltr{
    direction: ltr;
    text-align: left;
    
    > *{
      text-align: left;
      direction: rtl;
    }
  }
}

.sectionSecond {
  margin-top: var(--space-xl);
}

.title {
  font-size: var(--fs-large);
  line-height: 1.2;
  
  color: black;
  margin-bottom: var(--space-lg);
  letter-spacing: 0.01em;
  >span{
    font-weight: 500 !important;
  }
  &.ltr{
    direction: ltr;
  }
}

.redText {
  color: #E21F1F;
  font-weight: 500 !important;
}

.greenText {
  color: #26AD33;
  font-weight: 500 !important;
}

.list {
  text-align: right;
  // width: 100%;
  align-self: baseline;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: var(--space-sm);
  font-size: var(--fs-medium);
  line-height: 1.2;
  letter-spacing: 0.01em;
  direction: ltr;
}

.listItem {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.crossIcon {
  color: #FF0000;
  margin-top: 0.25rem;
  min-width: 25px;
  font-size: var(--fs-medium);
}

.checkIcon {
  all: unset;
  stroke: #26AD33 !important;
  fill: #26AD33 !important;
  margin-top: 0.25rem;
  height: 1em;
  width: 1em;
}

.boxTitle {
  font-size: var(--fs-medium);
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  margin-bottom: var(--space-md);
  max-width: 1274px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--space-xl);
}

.boxDescription {
  direction: rtl;
  font-size: var(--fs-medium);
  line-height: 1.2;
  text-align: center;
  font-weight: normal;
  max-width: 1274px;
  margin-left: auto;
  margin-right: auto;
  &.ltr{
    direction: ltr;
  }
}

.box {
  direction: rtl;
  max-width: 1173px;
  margin: var(--space-lg) auto 0;
  border: 6px dashed #FF5E5E;
  border-radius: 33px;
  padding: var(--space-md);
  &.ltr{
    direction: ltr;
  }
}

.boxHeading {
  font-size: var(--fs-medium);
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: black;
}

.boxParagraph {
  font-size: var(--fs-large);
  line-height: 1.2;
  text-align: center;
  font-weight: normal;
  margin-top: var(--space-sm);
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--space-md);
  max-width: 95%;
  margin: var(--space-lg) auto;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: var(--space-xl);
  }
}

.arrowIcon {
  width: 50px;
  height: 83px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: 0;
  }
}

.gradientButton {
  background: linear-gradient(90deg, #1B6F22 19.8%, #30C43C 47.38%, #28A432 73.15%);
  width: 100%;
  height: clamp(70px, 15vw, 92px);
  border-radius: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  @media (min-width: 768px) {
    width: clamp(300px, 50vw, 514.8px);
  }
}

.buttonText {
  color: white;
  font-weight: bold;
  font-size: var(--fs-large);
  line-height: 1.2;
  text-align: center;
  margin-right: 2rem;
  background-color: transparent;
  border: none;
}

.trialDays {
  color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    border: 1px solid black;
    height: 100%;
    aspect-ratio: 1;
    border-radius: 50%;


}

.trialNumber {
  font-size: var(--fs-medium);
  font-weight: 600;
  margin-top: -0.5rem;
}

.trialText {
  font-size: var(--fs-small);
  line-height: 5px;
  text-align: center;
  font-weight: bold;
}

.startButton {
  color: white;
  border-radius: 0.75rem;
  width: 100%;
  height: 70px;
  font-weight: bold;
  font-size: var(--fs-large);
  line-height: 1.2;
  text-align: center;
  background-color: #FF4747;
  margin-bottom: var(--space-sm);

  @media (min-width: 768px) {
    height: 115px;
    margin-bottom: var(--space-md);
  }
}

.cardsImage {
  display: block;
  max-width: 400px;
  margin: var(--space-md) auto;
  width:100%;
}