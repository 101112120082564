.BillingBackDrop {
    // background-color: #ffffff40;
    width: 100vw;
    height: 100dvh;
    position: fixed;
    top: 0%;
    left: 0%;
    z-index: 2;
    backdrop-filter: blur(7px);
    // background-color: rgba(30, 30, 30, 0.243);
}

.BillingBackDrop .BillingModalContainer {
    position: absolute;
    max-width: 700px;
    width: 80vw;
    height: 84dvh;
    max-height: 715px;
    background-color: rgb(255, 255, 255);
    color: black;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1.5% 0.5% 0%;
    font-family: Poppins;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* gap: 2rem; */
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    overflow-y: auto;
}

.ShowChatContainer {
    opacity: 1 !important;
}

.PhoneNumberForm {
    // max-height: 442px;
    width: 100%;
    flex: 1 1 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(128, 128, 128, 0.552);
    gap: 10%;
    max-height: 78.5%;
}

.PhoneNumberFormTitle {
    display: inline-block;
    text-align: center;
    // margin-top: 5rem;
    font-size: 25px;
    font-family: poppins;
}

.PhoneInput {
    background-color: white;
    border-radius: 7px;
    --PhoneInputCountryFlag-height: 25px !important;
    width: 60%;
    max-width: 400px;
    border: none;
    outline: none;
    height: 56px;
    // margin: 20% 0 41%;
    padding: 0 1rem;
    font-size: clamp(1rem, 0.8vw, 3rem);
    letter-spacing: 1px;

    >div {
        width: 15%;

        >div {
            height: unset;
        }
    }

    >input {
        width: 85%;
        border: none;
        outline: none;
        background-color: transparent;

        &:focus {
            outline: none;
            border: none;
        }
    }

}


.BillingIframe {
    height: 100%;
    width: 100%;
    border: none;
}

.CloseLetsChatModalModalButton {
    position: absolute;
    top: 10px;
    right: 5px;

}



@media(max-width: 1150px) {
    .BillingBackDrop .BillingModalContainer {
        padding: 1.5rem 1rem 0;
    }

    // .PhoneNumberForm {
    //     min-height: 350px;
    // }
}

@media(max-width: 560px) {
    .BillingBackDrop .BillingModalContainer {
        padding: 2.5rem 1rem 1rem;
        min-width: 275px;
        gap: 1rem;
        top: 50%;
        height: 100%;
        max-height: 85dvh;
    }

    // .PhoneNumberForm {
    //     min-height: 350px;
    // }
}

// @media(max-width: 394px) {
//     .BillingIframe {
//         min-height: 400px;
//     }
// }

.iFrameLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}