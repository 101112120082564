.FaqSectionMainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
    padding: 5rem 0 5rem;
    color: white;

    >p {
        max-width: 60%;
        font-size: var(--header);
        font-weight: 700;
        line-height: var(--headerLineHeight);
        letter-spacing: 0.45px;
        text-align: center;
    }
}

.FaqSectionMainContainer .FaqSectionAboutInfoCardContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
}

@media (max-width: 1378px) {


    .FaqSectionMainContainer {
        padding: 5rem 0 5rem;

        >p {
            text-align: center;
        }
    }


}

@media (max-width: 492px) {


    .FaqSectionMainContainer {
        >p {
            max-width: 80%;
            // font-size: clamp(2rem, 2vw + 1rem, 9rem);
            // line-height: clamp(2.2rem, 2.5vw + 1rem, 9rem);
            letter-spacing: 0.45px;
        }
    }


}