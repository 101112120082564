.PlanCardsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // flex-wrap: wrap;
    gap: 2rem;
    padding: 10rem 1rem;

    >p {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;
        font-size: var(--header);
        line-height: var(--headerLineHeight);

        > :first-child {
            text-transform: capitalize;
            font-weight: 700;
            text-align: center;
        }
    }


}

.PricingPlanCardsDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // align-items: center;
    gap: 4rem;
    padding: 4rem 0;

}

.HebPlanCardsDirection {
    flex-direction: row-reverse;
}

@media (max-width: 1378px) {


    .PlanCardsContainer {
        padding: 5rem 1rem;
    }


}

@media (max-width: 492px) {


    .PlanCardsContainer {
        >p {
            max-width: 79%;
            color: white;

            // font-size: clamp(2rem, 2vw + 1rem, 9rem);
            >:first-child {
                font-size: clamp(2.4rem, 4.5vw, 6rem);
                line-height: clamp(3.1rem, 5vw, 6rem);
            }
        }
    }


}