.aboutMyselfComponent {
  font-family: 'Rubik', sans-serif;

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }

  p, span, div {
    font-weight: 400;
  }
}

.container {
  color: black;
  padding: 0 var(--space-sm);
  background-color: white;
}

.containerLtr{
  direction: ltr;
}

.content {
  max-width: min(100%, 1465px);
  width: 100%;
  margin: var(--space-lg) auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row-reverse;
  }
}

.textContent {
  max-width: 100%;
  margin-bottom: var(--space-md);
  @media (min-width: 768px) {
    max-width: 643px;
    margin-bottom: 0;
    margin-right: var(--space-md);
  }
}

.heading, .subheading, .boldSubheading {
  text-align: right;
  font-size: var(--fs-medium);
  line-height: 1.5;
  letter-spacing: 0.04em;
  margin-bottom: var(--space-sm);
}

.heading, .boldSubheading {
  font-weight: bold;
}

.subheading {
  font-weight: normal;
}

.imageContainer {
  width: clamp(250px, 80vw, 500px);
  height: clamp(250px, 80vw, 500px);
  margin: 0 auto;
}

.image {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #000000 0%, #B398EF 100%);
}

.additionalContent {
  max-width: min(100%, 995px);
  margin: 0 auto var(--space-lg);
  text-align: center;
  padding: 0 var(--space-sm) 1px var(--space-sm);
}

.largeHeading {
  font-size: var(--fs-medium);
  line-height: 1.5;
  letter-spacing: 0.04em;
  font-weight: bold;
  margin-bottom: var(--space-md);
}

.paragraph {
  font-size: var(--fs-medium);
  line-height: 1.5;
  letter-spacing: 0.04em;
  font-weight: normal;
  margin-bottom: var(--space-md);
}

.coloredParagraph {
  @extend .paragraph;
  color: #B398EF;
}

.coloredHeading {
  @extend .largeHeading;
  color: #B398EF;
}