.serviceDataCardContainer{
    padding: 4rem 2rem;
    display:flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #27272784;
    max-width: 250px;
    border-radius: 15px;
    border: 1px solid rgb(55, 54, 54);
}

.imgSource{
    height: 50px;
    width: 50px;
}

.headerTitle{
    color: white;
    font-size: 20px;
}

.paragraphText{
    color: #A0A2A4;
    font-size: 14px;
    line-height: 28px;
}