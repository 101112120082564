// .forStarters {
//     text-align: right;
//     padding: 70px 0;
//     background-color: #B398EF;
//     width: 100%;
//     color: white;
  
//     @media (min-width: 768px) {
//       padding: 100px 0;
//     }
  
//     @media (min-width: 1024px) {
//       padding: 142px 0;
//     }
//   }
  
//   .container {
//     max-width: 1274px;
//     margin: 0 auto;
//     padding: 0 16px;
  
//     @media (min-width: 768px) {
//       padding: 0 40px;
//     }
//   }
  
//   .content {
//     text-align: center;
//   }
  
//   .mainHeading {
//     font-size: 30px;
//     line-height: 40px;
//     letter-spacing: 0.01em;
//     font-weight: normal;
//     margin-bottom: 32px;
  
//     @media (min-width: 768px) {
//       font-size: 50px;
//       line-height: 70px;
//       margin-bottom: 48px;
//     }
  
//     @media (min-width: 1024px) {
//       font-size: 70px;
//       line-height: 95px;
//       margin-bottom: 56px;
//     }
//   }
  
//   .paragraph {
//     font-size: 20px;
//     line-height: 30px;
//     letter-spacing: 0.01em;
//     font-weight: normal;
  
//     @media (min-width: 768px) {
//       font-size: 40px;
//       line-height: 50px;
//     }
  
//     @media (min-width: 1024px) {
//       font-size: 70px;
//       line-height: 95px;
//     }
//   }
  
//   .subHeading {
//     font-size: 30px;
//     line-height: 40px;
//     letter-spacing: 0.01em;
//     font-weight: normal;
//     margin-top: 32px;
  
//     @media (min-width: 768px) {
//       font-size: 50px;
//       line-height: 70px;
//       margin-top: 48px;
//     }
  
//     @media (min-width: 1024px) {
//       font-size: 70px;
//       line-height: 95px;
//       margin-top: 56px;
//     }
//   }
  
//   .boldHeading {
//     @extend .subHeading;
//     font-weight: bold;
//     padding: 32px 0;
  
//     @media (min-width: 768px) {
//       padding: 48px 0;
//     }
  
//     @media (min-width: 1024px) {
//       padding: 56px 0;
//     }
//   }
  
//   .finalHeading {
//     font-size: 20px;
//     line-height: 30px;
//     letter-spacing: 0.01em;
//     font-weight: normal;
  
//     @media (min-width: 768px) {
//       font-size: 40px;
//       line-height: 50px;
//     }
  
//     @media (min-width: 1024px) {
//       font-size: 70px;
//       line-height: 95px;
//     }
//   }
  
//   .buttonWrapper {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
  
//   .button {
//     background-color: #231154;
//     border-radius: 12px;
//     height: 60px;
//     width: 200px;
//     font-size: 18px;
//     line-height: 30px;
//     letter-spacing: 0.04em;
//     margin-top: 70px;
//     font-weight: bold;
//     color: white;
  
//     @media (min-width: 768px) {
//       height: 90px;
//       width: 350px;
//       font-size: 30px;
//       line-height: 50px;
//       margin-top: 100px;
//     }
  
//     @media (min-width: 1024px) {
//       height: 115px;
//       width: 512px;
//       font-size: 36px;
//       line-height: 82px;
//       margin-top: 142px;
//     }
//   }



.forStarters {
  text-align: right;
  padding: var(--space-xl) 0;
  background-color: #B398EF;
  width: 100%;
  color: white;
  &.ltr{
    direction: ltr;
  }
}

.container {
  max-width: 1274px;
  margin: 0 auto;
  padding: 0 var(--space-xs);
}

.content {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
}

.mainHeading {
  font-size: var(--fs-large);
  line-height: 1.35;
  letter-spacing: 0.01em;
  font-weight: normal;
  // margin-bottom: var(--space-md);
}

.paragraph {
  font-size: var(--fs-medium);
  line-height: 1.35;
  letter-spacing: 0.01em;
  font-weight: normal;
}

.subHeading {
  font-size: var(--fs-medium);
  line-height: 1.35;
  letter-spacing: 0.01em;
  font-weight: normal;
  // margin-top: var(--space-md);
}

.boldHeading {

  font-weight: bold;
  // padding: var(--space-md) 0;
}

.finalHeading {
  font-size: var(--fs-large);
  line-height: 1.5;
  letter-spacing: 0.01em;
  font-weight: normal;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  background-color: #231154;
  border-radius: 12px;
  height: clamp(60px, 8vw, 115px);
  width: clamp(200px, 30vw, 512px);
  font-size: var(--fs-large);
  line-height: 1.5;
  letter-spacing: 0.04em;
  margin-top: var(--space-xl);
  font-weight: bold;
  color: white;
}

@media (min-width: 768px) {
  .container {
    padding: 0 var(--space-sm);
  }
}