
/* html{
  height:100%;
}
body {
  min-height:100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.btn_accessibility{
  top: 110px !important;
  left: 20px !important;
  right: unset !important;
}
@import-normalize;
.uai {
  position: fixed;
  top: 80vh !important;
  right: 100px !important;
}
html {
  font-size: 16px;
  /* height:100%; */
}


body {
  /* height:100%; */
  font-family: Rubik;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  background-color: white;
}

#root {
  /* height:100%; */
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}



.Header, .Footer {
  width: 100%;
  /* background-color: #66d8f5; */
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* @media (max-width:500px){
  .uai {
    position: fixed;
    top: 80vh !important;
  right: 100px !important;
  }
} */