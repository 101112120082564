.WhatsAppExampleSectionContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 3.5rem 0 0 0;

  >div {
    >p {
      font-family: Poppins;
      font-size: var(--header);
      font-style: normal;
      font-weight: 700;
      line-height: var(--headerLineHeight);
      letter-spacing: 0.96px;
      width: 80%;
    }
  }

  >img {
    margin: 0 auto;
    height: 75%;
  }
}

.WhatsAppExampleSectionContainer .SecondRowStartFreeDiv {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  max-width: 90%;
  margin: 0 auto;
  padding-left: 30%;
}

.ThirdRowWhatsappChatContainer {
  // border: 1px solid lightgrey;
  background-color: #313333;
  // height: 80%;
  width: 90%;
  max-width: 450px;
  align-self: flex-end;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  height: 90%;

  >span {
    display: flex;
    height: 14%;
    width: 100%;
    border-bottom: 1px solid lightgray;
    justify-content: space-around;
    margin: 10px 0 0;

    > :nth-child(2) {
      margin-left: 20px;
      height: 66px;
    }

    > :last-child {
      height: 15px;
      margin-right: -17px;
    }
  }
}

.SecondMessageAsText {
  background-color: #ffffff;
  color: black;
  margin-top: 6%;
  padding: 3rem 1rem;
  border-radius: 25px;
  border-bottom-left-radius: 0;
}

.SecondMessageAsPhoto {
  background-color: transparent;
  margin-top: 2rem;
  padding: 1rem 1rem;
}

.ThirdRowStartFreeDiv {
  display: flex;
  /* justify-content: center; */
  // align-items: center;
  // text-align: center;
  flex-direction: column;
  // max-width: 90%;
  // margin-left: 25%;
  padding: 3.5rem 0px 2.5rem;
  margin-left: 5rem
}

.HebClass {
  text-align: center;
  // align-items: center !important;
  margin: 0;
}

.ThirdRowWhatsappChatContainer .WhatsAppChat {
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 2rem;

  >p:first-child {
    background-color: #17c3ce;
    padding: 1.3rem 1.3rem;
    border-radius: 25px;
    border-bottom-right-radius: 0;
  }
}

.ThirdRowWhatsappChatContainer .WhatsAppChat .WhatsAppChatInputSpan {
  // height: 45px;
  // width: 83%;
  display: flex;
  justify-content: flex-end;
  background-color: #1d1d1d;
  border: 1px solid #676767;
  border-radius: 7px;
  align-items: center;
  padding: 0.2rem 1rem;
  margin-top: 3rem;
}

.CustomWhatsAppImgContainer {
  margin: 4rem 0 0;
  min-width: 285px;
  max-width: 70%;
  width: 400px;
  justify-self: center;
}

@media (max-width: 1378px) {
  // .CustomWhatsAppImgContainer {
  //   margin-bottom: 4rem;

  // }

  .WhatsAppExampleSectionContainer {
    grid-template-columns: 1fr;
    padding: 0;

  }

  .WhatsAppExampleSectionContainer .SecondRowStartFreeDiv {
    padding-left: 0;

    align-items: center;
    margin-bottom: 2rem;
  }

  .WhatsAppExampleSectionContainer {
    >div {
      >p {
        font-size: var(--header);
        font-style: normal;
        line-height: var(--headerLineHeight);
        text-align: center;
      }
    }
  }

  .ThirdRowStartFreeDiv {
    align-items: center;
    margin: 0rem auto;
    grid-row-start: 1;
    // flex-direction: column-reverse;
    justify-content: center;
    padding: 5rem 0px 2.5rem !important;
  }

  .HebClass {
    align-items: center !important;
  }
}

@media (max-width: 492px) {
  .WhatsAppExampleSectionContainer {
    >div {
      >p {
        font-size: var(--header);
        line-height: var(--headerLineHeight);
        letter-spacing: 0.96px;
        width: 78%;
      }
    }
  }

  .SecondMessageAsPhoto {
    padding: 0;
  }
}