.videoSectionMainContainer{
    display: flex;
    // grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 2rem 0;
    padding: 5% 0;
    .contentContainer{
        flex: 1;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .videoPlayerSide{
            flex: 1;
            // width: min(80%, 800px);
            width:80%;
            max-width: 1200px;
            // height: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
            .videoPlayer{
                width: 100%;
                height: 100%;
            }
        }
        .textContentAndButton{
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            // align-items: flex-end;
            direction: rtl;
        padding: 0 5%;

            > p{
                font-family: 'Poppins', sans-serif;
                font-weight: 500 ;
                font-size: var(--header);
                line-height: var(--header);
                width: 93%;
            }

        }
    }
    @media(max-width: 1380px){
        flex-direction: column;
        .contentContainer{
            width: 100%;
            .videoPlayerSide{
                margin-top: 80px;
            }
            .textContentAndButton{
                align-items: center;
                flex-direction: column-reverse;
                // direction: ltr;
                text-align: center;
                padding: 0;
                > p{
                    margin: 80px 0;
                    // width: 100%;
                }
            }
        }

    }
}