.BillingIframe {
    height: 100%;
    width: 100%;
    border: none;
}

.iFrameLoader {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.somethingWentWrongMsgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: rgba(255, 0, 0, 0.744);
    font-size: clamp(1.2rem, 1.5vw, 1.6rem);
    margin-bottom: 4rem;
    text-align: center;

    >span {
        width: 70%;
    }
}