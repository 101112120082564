.PrivacyAndTOSMainContainer{
    // padding-top:5rem;
    height: 96dvh;
    >div{
        position: relative;
        height: 100%;
        overflow-y:auto;
        .headerContainer{
            background-color: #2E2E2E;
            position: sticky;
            top:0;
            font-family: Poppins,sans-serif;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            height:20%;
            padding: 2rem 0;
            >h1{
                font-size:clamp(1.2rem, 6vw, 3rem);
            }
        }
        .contentContainer{
            // height: 70vh;
            position:relative;
            background-color: white;
            box-sizing: border-box;
            display:flex;
            padding: 4rem 14%;
            gap:1rem;
            .contentLinks{
                position:sticky;
                top:8%;
                display: flex;
                flex-direction: column;
                // align-items: center;
                // text-align: left;
                padding-top: .3rem;
                width: 30%;
                height: 100%;
                gap: 1rem;
                transition: all 0.2s ease;
                >a{
                    all: unset;
                    font-size:clamp(0.7rem, 3vw, 1rem);
                    cursor: pointer;
                    width: fit-content;
                    &:hover{
                        transition: all 0.2s ease;
                        color: #FD4766;
                    }
                }
            }
            .contentDescriptionContainer{
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                color: black;
                width: 70%;
                font-family: Roboto;
                font-weight: 500;
                >div{
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    >span{
                        font-size:clamp(1rem, 3vw, 1.2rem);
                    }
                    >pre{
                        all:unset !important;
                        font-size:clamp(0.7rem, 3vw, 1rem) !important;
                        font-family: Roboto, sans-serif;
                        font-weight: 400;
                        margin: 0;
                        white-space: pre-wrap;       /* CSS 3 */
                        white-space: -moz-pre-wrap;  /* Firefox */
                        white-space: -pre-wrap;      /* Opera <7 */
                        white-space: -o-pre-wrap;
                        max-width:900px !important;
                    }
                }
            }
        }
    }
    }

    .chosenLink{
        color:#FD4766 !important;
        text-decoration: underline !important;
    }