.StartFreeButtonContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 0.5rem;
}
.StartFreeButton {
  // width: fit-content;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // appearance: none;
  // cursor: pointer;
  // margin-top: 5rem;
  // padding: 0.5rem 3.5rem 0.5rem 1.3rem;
  // display: flex;
// justify-content: flex-start;
  // align-items: center;
  // border-radius: 30px;
  // color: black;
  // direction: ltr !important;
  // border: 1px solid black;
  // gap: 3%;

  // // gap: 20%;
  // // -webkit-appearance: none;
  // // -moz-appearance: none;
  // // appearance: none;
  // >img {
  //   height: 50px;
  //   width: 50px;
  //   text-align: left;
  //   // flex-basis: 20%;
  // }

  cursor: pointer;
  margin-top: 5rem;
  border-radius: 30px;
  color: black;
  direction: ltr !important;
  border: 1px solid black;
  padding: 0 .5rem;
  height: 60px;
  min-width: 250px;
  width: fit-content;
  
}

.StartFreeButtonNegative {
  color: white;
  border: 1px solid white;
  background-color: transparent;
}

.moneyBackGuarantee{
font-size: 11px !important;
text-align: center;
}
.StartFreeButton:active {
  box-shadow: 3px 3px 3px inset rgb(0, 0, 0);
  //   background: linear-gradient(to right, #1c99fe, #7644ff, #fd4766);
  //   width: -moz-fit-content;
  //   width: fit-content;
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent;
}

.StartFreeButton .StartFreeButtonTitle {
  text-align: center;
  font-family: Roboto;
  font-size: clamp(0.8rem, 1vw, 1rem);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.4px;
  // text-transform: uppercase;
  white-space: nowrap;
  flex: 1 1;
  text-align: center;
}

.accessLinkATag{
  all:unset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  margin-top: 5rem;
  border-radius: 30px;
  color: black;
  direction: ltr !important;
  border: 1px solid black;
  // padding: 1.3rem 4rem;
  height: 60px;
  width: 250px;
  
  >p{
    font-family: Roboto;
    font-size: clamp(.8rem,1vw,1rem);
    font-style: normal;
    font-weight: 700;
    letter-spacing: 2.4px;
    line-height: normal;
    text-align: center;
    white-space: nowrap;
  }
}

.accessLinkATagNegative{
@extend .accessLinkATag;
background-color: transparent;
border-color:white;
color: white;
}
.ButtonIconAndTitle {
  display: flex;
  align-items: center;
  justify-content: space-around;
  // max-width: 80%;
  gap: 1rem;
  // width: 80%;
  padding: 3%;
  >img{
    max-width: 30%;
    
  }
  // width: 100%;
  // text-align: center;


}

// @media (max-width: 768px) {
//   .StartFreeButton {
//     padding: 0.5rem 2rem 0.5rem 0.6rem;
//   }
// }