.UniPricingPageMainContainer {
    display: grid;
    grid-template-rows: repeat(auto-fit, auto);
    height: calc(97vh - 3%);
    height: calc(97dvh - 3%);
    height: calc(97svh - 3%);
    // width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: Poppins;

    >*:not(:last-child) {
        // min-height: 100svh !important;
        height: max-content !important;
    }

    // >div {
    //     grid-column: 1 / -1;
    // }

    >div:nth-child(even) {
        background-color: #181818;
    }
    >div:nth-child(odd) {
        background-color: black;
    }
    
}

@media (max-width: 1700px) {
    .UniPricingPageMainContainer {
        height: calc(97vh - 2%);
        height: calc(97dvh - 2%);
        height: calc(97svh - 2%);
    }
}

@media (max-width: 1190px) {
    .UniPricingPageMainContainer {
        height: calc(97vh - 1%);
        height: calc(97dvh - 1%);
        height: calc(97svh - 1%);
    }

}

@media (max-width: 500px) {
    .UniPricingPageMainContainer {
        height: calc(92vh);
        height: calc(92dvh);
        height: calc(92svh);
    }
}