.ExampleImgCardMainContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 500px;
    gap: 1rem;
    margin-bottom: 2rem;

    >span {
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.18px;
        color: white;
    }

    >p {
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 1.3px;
        color: #A0A2A4;
    }
}

.HebClass {
    direction: rtl;
}

@media (max-width: 560px) {
    .ExampleImgCardMainContainer {
        padding: 1rem;
        max-width: 320px;
    }
}