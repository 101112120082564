.loginPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 100%;
    background-color: white;


    >* {
        font-family: sans-serif;
        box-sizing: border-box;
    }
}

.loginPageContainerHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0 0;
    flex: 0 0 auto;

    >img {
        width: 150px;
    }
}

// .loginPageMainSection {
//     flex: 1 0 auto;
//     min-height: 0;
//     display: flex;
//     align-items: flex-start;
//     justify-content: center;
//     justify-items: center;
//     width: 100%;
//     padding: 10%;
//     padding: calc(80);
//     height: -webkit-fit-content;
//     height: -moz-fit-content;
//     height: fit-content;

// }
.loginPageMainSection {
    flex: 1 0 auto;
    min-height: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    // padding: 10%;
    // padding: 80px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

}

.widgetAutoLayout {
    display: flex;
    flex-direction: column;
    height: auto;
    white-space: normal;
    background-color: none;
    border-radius: 5px;
    // min-width: 260px;
    width: 100%;
    max-width: 335px;
}

.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    text-align: center;

    >h1 {
        font-size: 2rem;
        font-weight: 550;
        padding: 10px 0px 0px;
    }

    >h3 {
        font-size: 1.2rem;
        font-weight: 400;
    }

    .continueButton {
        background-color: #4F46E5 !important;
        width: 100%;
        height: 56px;
        color: white;
        font-weight: 550;

        :hover {
            all: unset !important;
        }
    }
}

.inputAndHeaderContainer{
    width: 100%;
    margin-top:2rem;
    >h3{
        font-size: 1.5rem;
        margin-bottom:1rem;
    }
    .emailPrivacyText{
        color: #777B83;
        font-size: 14px;
        // padding-right: 30px;
        text-align: left;
        margin-top: 5px;
    }
}

.loginPageFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6e6e80;
    padding: 12px 0 24px;
    flex: 0 0 auto;
    gap: 5px;

    >a {
        color: #4F46E5
    }
}

.helperText {
    color: red;
}
.errorMessage {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}
.connectWithSection{
    border-top: 1px solid rgba(128, 128, 128, 0.378);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3%;
    margin-top: 10%;
    // max-width:90vw;
    width:100%;
    font-weight: 550;
    color:white;
    max-width: 335px;
    .connectWithOption{
        background-color:#0D9488;
        width:100%;
        color: white;
        border:none;
        align-items: center;
        display:flex;
        justify-content: space-around;
        gap: 5px;
        border-radius: 4px;
        padding: 0.8rem 1rem;
        // border: 1px solid grey;
    }
}

.PhoneNumberForm {
    // max-height: 442px;
    // width: 100%;
    // flex: 1 1 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background-color: rgba(128, 128, 128, 0.552);
    border-radius: 4px;
    gap: 10%;
    max-height: 56px;
    direction: ltr;
}

.PhoneNumberFormTitle {
    display: inline-block;
    text-align: center;
    // margin-top: 5rem;
    font-size: 25px;
    font-family: poppins;
}

.PhoneInput {
    border: 1px solid #c2bfbf;
    background-color: white;
    border-radius: 4px;
    --PhoneInputCountryFlag-height: 25px !important;
    // width: 100%;
    max-width: 400px;
    outline: none;
    height: 56px;
    // margin: 20% 0 41%;
    padding: 0 1rem;
    font-size: clamp(1rem, 0.8vw, 3rem);
    letter-spacing: 1px;

    >div {
        width: 15%;

        >div {
            height: unset;
        }
    }

    >input {
        width: 85%;
        border: none;
        outline: none;
        background-color: transparent;

        &:focus {
            outline: none;
            border: none;
        }
    }

}

@media (max-width: 570px) {
    .loginPageMainSection {
        align-items: flex-start;
        padding: 1rem 2rem;
    }

    .loginContainer {

        >h1 {
            padding-top: 20px;
        }
    }
}

@media (max-width: 380px) {
    .loginPageMainSection {
        padding: 0;
    }

    .loginPageFooter {
        padding: 0;
    }
}

@media(max-height: 800px) {
    .loginPageContainerHeader {
        padding: 0
    }
}