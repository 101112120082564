.StartFreeAndImgContainer {
  display: flex;
  align-items: center;
  color: white;
  position: relative;

  >div {
    >p {
      font-family: Poppins;
      font-size: var(--header);
      font-style: normal;
      font-weight: 700;
      line-height: var(--headerLineHeight);
      letter-spacing: 0.96px;
      // width: 75%;
      max-width: 90%;
    }
  }
}

.Reverse {
  flex-direction: row-reverse;
}

.DirectionColumn {
  flex-direction: column;
  padding: 9rem 0;
}

.ImagesContainer {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  gap: 2rem;
  flex-wrap: wrap;
  width: 50%;
}

.StartFreeAndImgContainer .StartFreeAndLabelDiv {
  box-sizing: border-box;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 50%;
}

.HebClass {
  text-align: center;
  direction: rtl;
  align-items: center;
}

@media (max-width: 1378px) {
  .StartFreeAndImgContainer {
    flex-direction: column-reverse;

    >div {
      >p {
        font-size: var(--header);
        // font-size: clamp(3.5rem, 3vw + 1rem, 12rem);
        font-style: normal;
        line-height: var(--headerLineHeight);
        // line-height: clamp(3.5rem, 3vw + 1rem, 12rem);
        text-align: center;
        width: 60%;
      }
    }

  }

  .DirectionColumn {
    padding: 0;
  }

  .ImagesContainer {
    margin: 0 !important;
    // flex: 1 1 100%;
    width: 100%;

    >img {
      align-self: flex-end;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
  }

  .StartFreeAndImgContainer .StartFreeAndLabelDiv {
    // padding-left: 0;
    // align-items: center;
    margin: 6rem 0;
    // width: 100%;
    flex-direction: column-reverse;
    max-width: 100% !important;
    align-items: center !important;
    text-align: center !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  // .ThirdRowStartFreeDiv {
  //     text-align: center;
  // }

  .StartFreeAndImgContainer {
    >div {
      >p {
        font-size: var(--header);
        // font-size: clamp(3.5rem, 3vw + 1rem, 12rem);
        font-style: normal;
        line-height: var(--headerLineHeight);
        // line-height: clamp(3.5rem, 3vw + 1rem, 12rem);
        text-align: center;
        width: 60%;
      }
    }
  }
}
.twoButtonsFlexColumn{
  flex-direction: column !important;
}
.twoButtonsContainer{
  display: flex;
  justify-content: center;
  gap:10%;
  width: 90%;
  
  >div:nth-child(2){
    >button{
      background-color: var(--defaultTurquoise);
      color:white;
    }
  }
}

.phoneInputAndFreeButton{
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.whatsAppPhoneInput{
  all:unset;
  width: 70%;
  padding: 1rem;
  background-color: #292927;
  border-radius: 30px;
  border: 2px solid white;
  text-align:left;
  &::placeholder{
    direction: rtl;
    text-align: right;
    font-size:clamp(0.8rem, 1vw, 20px);
    color: rgba(255, 255, 255, 0.544);
    margin-right:5%;
  }
}
.WhatsAppPhoneInputEng{
  direction: ltr;
  text-align: left;
  &::placeholder{
    direction: ltr;
    text-align: left;
    margin-left:5%;
  }
}

// @media (max-width: 1378px) {
//   .StartFreeAndImgContainer .StartFreeAndLabelDiv {
//     align-items: center !important;
//     text-align: center !important;
//     padding-left: 0 !important;
//   }
// }
@media(max-width: 1378px) {
  .twoButtonsContainer{
    flex-direction: column;
    align-items: center;
    margin-top:4rem;
    >button{
      margin-bottom: 1rem !important;
    }
  }
  .phoneInputAndFreeButton{
    margin-top:0;
    
  }
  .phoneInputAndErrorMessage{
    display: flex !important;
    flex-direction: column !important;
    margin: 0 0 2rem 0 !important;
  }
  .whatsAppPhoneInput{
    margin-top: 0;
    padding: 1rem;
    width: 218px;
  }
}

@media (max-width: 492px) {
  .StartFreeAndImgContainer {
    >div {
      >p {
        // font-size: clamp(2.2rem, 5vw, 6rem);
        // line-height: clamp(2.9rem, 5vw, 6rem);
        letter-spacing: 0.96px;
        width: 80%;
      }
    }
  }



  // .ThirdRowWhatsappChatContainer .WhatsAppChat {
  //     >p:first-child {
  //         font-size: medium;
  //         padding: 1.3rem 1.3rem;
  //     }

  // > p:scond-child {
  //   font-size: small;
  //   padding: 1.3rem 1.3rem;
  // }
  // }

  // .ForthRowFiguresContainer {
  //     >p {
  //         font-size: clamp(2rem, 2vw + 1rem, 9rem);
  //         letter-spacing: 0.65px;
  //         width: 90%;
  //     }
  // }

  // .FifthRowMainContainer {
  //     >p {
  //         max-width: 90%;
  //         font-size: clamp(2rem, 2.5vw + 1rem, 9rem);
  //         line-height: clamp(2.2rem, 2.5vw + 1rem, 9rem);
  //         letter-spacing: 1.28px;
  //     }
  // }

  // .SixthRowMainContainer {
  //     >p {
  //         max-width: 90%;
  //         color: white;
  //         font-size: clamp(2rem, 2vw + 1rem, 9rem);
  //     }
  // }

  // .SeventhRowMainContainer {
  //     >p {
  //         max-width: 90%;
  //         font-size: clamp(2rem, 2vw + 1rem, 9rem);
  //         line-height: clamp(2.2rem, 2.5vw + 1rem, 9rem);
  //         letter-spacing: 0.45px;
  //     }
  // }

  // .EighthRowMainContainer {
  //     >p {
  //         width: 90%;
  //         text-align: center;
  //         font-family: Poppins;
  //         font-size: clamp(2rem, 2vw + 1rem, 9rem);
  //         line-height: clamp(2.5rem, 2vw + 1rem, 9rem);
  //     }
  // }
}

@media (max-width:355px) {
  .ImagesContainer {
    width: 80%;
    margin: 0 auto !important;
  }
}