.languageSelectInput {

    >div {
        display: flex;
        align-items: center;
        padding: 0 32px 0 5px;
    }

    >svg {
        color: white;
    }
}

.rtlLang {
    >div {
        display: flex;
        align-items: center;
        padding: 0 10px 0 0px;
    }
}