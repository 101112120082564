.moneyBackSection {
  padding: 4rem 0;
  color:white;
  min-height: unset !important;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.imageWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
  }
}

.content {
  flex: 1;

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    // font-size: var(--header);
    // color: #333;
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
    color: #666;
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      font-size: 1rem;
      margin-bottom: 0.5rem;
      color: #666;
      position: relative;
      padding-left: 1.5rem;

      &::before {
        content: '✓';
        color: #4CAF50;
        position: absolute;
        left: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    padding: 0 1rem;
  }

  .imageWrapper,
  .content {
    flex: none;
    width: 100%;
  }
}