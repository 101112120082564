.FiguresCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  // flex-wrap: wrap;
  padding: 2rem 2rem;
  height: 225px;
  width: 225px;
  gap: 5%;
  background-color: #1f1e1e;
  border-radius: 7px;
  text-align: center;

  >span:nth-child(2) {
    color: #4ae0db;
    font-size: 55px;
  }
}