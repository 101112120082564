.ForthRowFiguresContainer {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-family: Poppins;
  font-weight: 700;
  padding: 6rem 0;
  gap: 5rem;
  min-height: 800px;
  >p {
    text-align: center;
    font-size: var(--header);
    font-style: normal;
    font-size: var(--header);
    letter-spacing: 0.65px;
  }
  .FiguresContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4rem;
  }
}

