// .container {
//     background-color: black;
//     padding: 142px 4px 75px;
//     color: white;
//     direction: rtl;
//   }
  
//   .border {
//     max-width: 1015px;
//     margin: 0 auto;
//     border: 6px dashed #FF5E5E;
//     border-radius: 33px;
//     padding: 5px 10px;
  
//     @media (max-width: 767px) {
//       padding: 5px;
//     }
//   }
  
//   .title {
//     text-align: center;
//     font-size: 30px;
//     font-weight: bold;
//     line-height: 60px;
//     letter-spacing: 0.01em;
//     color: #FFFFFF;
  
//     @media (min-width: 640px) {
//       font-size: 40px;
//     }
  
//     @media (min-width: 768px) {
//       font-size: 60px;
//       line-height: 95px;
//     }
//   }
  
//   .faqContainer {
//     padding: 50px 0;
  
//     @media (min-width: 768px) {
//       padding: 75px 0;
//     }
  
//     @media (min-width: 1024px) {
//       padding: 100px 0;
//     }
//   }
  
//   .faqItem {
//     margin-bottom: 8px;
//   }
  
//   .faqQuestion {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     gap: 2px;
//     text-align: right;
//     cursor: pointer;
//   }
  
//   .icon {
//     min-width: 20px;
//     height: 20px;
  
//     @media (min-width: 768px) {
//       width: 30px;
//       height: 30px;
//     }
  
//     @media (min-width: 1024px) {
//       width: 37px;
//       height: 37px;
//     }
//   }
  
//   .questionText {
//     font-size: 20px;
//     line-height: 30px;
//     font-weight: normal;
//     letter-spacing: 0.01em;
  
//     @media (min-width: 768px) {
//       font-size: 30px;
//       line-height: 40px;
//     }
  
//     @media (min-width: 1024px) {
//       font-size: 40px;
//       line-height: 50px;
//     }
  
//     span {
//       margin-right: 5px;
//     }
//   }
  
//   .faqAnswer {
//     text-align: right;
//     transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
//     overflow: hidden;
//   }
  
//   .expanded {
//     max-height: 1000px;
//     opacity: 1;
//   }
  
//   .collapsed {
//     max-height: 0;
//     opacity: 0;
//   }
  
//   .answerText {
//     font-size: 16px;
//     line-height: 24px;
//     font-weight: normal;
//     text-align: right;
//     margin-top: 2px;
  
//     @media (min-width: 768px) {
//       font-size: 20px;
//       line-height: 30px;
//       margin-top: 4px;
//     }
  
//     @media (min-width: 1024px) {
//       font-size: 30px;
//       line-height: 40px;
//     }
//   }
  
//   .description {
//     text-align: right;
//     font-size: 20px;
//     line-height: 40px;
  
//     @media (min-width: 640px) {
//       font-size: 30px;
//     }
  
//     @media (min-width: 768px) {
//       font-size: 40px;
//       line-height: 60px;
//     }
//   }
  
//   .ctaContainer {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: space-between;
//     max-width: 95%;
//     gap: 30px;
//     margin: 0 auto;
//     margin-top: 4px;
//     margin-bottom: 70px;
  
//     @media (max-width: 767px) {
//       margin-top: 30px;
//       margin-bottom: 30px;
//     }
//   }
  
//   .cta {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background: linear-gradient(90deg, #1B6F22 19.8%, #30C43C 47.38%, #28A432 73.15%);
//     width: 100%;
//     height: 88px;
//     border-radius: 90px;
//     position: relative;
  
//     @media (min-width: 768px) {
//       width: 514.8px;
//       height: 128px;
//     }
//   }
  
//   .ctaButton {
//     font-size: 24px;
//     line-height: 1.2;
//     font-weight: bold;
//     letter-spacing: 0.01em;
//     text-align: center;
//     margin-right: 8px;
//     color: white;
//     background-color: transparent;
//     border: none;
  
//     @media (min-width: 768px) {
//       font-size: 48px;
//       line-height: 95px;
//     }
//   }
  
//   .daysContainer {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     position: absolute;
//     right: 0;
//     border: 1px solid black;
//     height: 90px;
//     width: 90px;
//     border-radius: 50%;
  
//     @media (min-width: 768px) {
//       height: 130px;
//       width: 133px;
//     }
//   }
  
//   .daysNumber {
//     text-align: right;
//     font-size: 24px;
//     font-weight: 600;
//     margin-top: -2px;
  
//     @media (min-width: 768px) {
//       font-size: 50px;
//       margin-top: -4px;
//     }
//   }
  
//   .daysText {
//     text-align: right;
//     font-size: 16px;
//     line-height: 5px;
//     letter-spacing: 0.01em;
//     font-weight: bold;
//     text-align: center;
  
//     @media (min-width: 768px) {
//       font-size: 30px;
//     }
//   }
  
//   .startButton {
//     background-color: #FF4747;
//     color: white;
//     font-size: 20px;
//     font-weight: bold;
//     line-height: 60px;
//     letter-spacing: 0.04em;
//     text-align: center;
//     width: 100%;
//     height: 70px;
//     border-radius: 12px;
//     margin-bottom: 2px;
  
//     @media (min-width: 768px) {
//       font-size: 36px;
//       line-height: 82px;
//       height: 115px;
//       margin-bottom: 40px;
//     }
//   }
  
//   .cardImage {
//     background-image: url('../../../../public/assets/cards.svg');
//     background-repeat: no-repeat;
//     background-position: center;
//     height: 40px;
//     max-width: 400px;
//     margin: 0 auto;
//     padding: 4px 0;
  
//     @media (min-width: 768px) {
//       max-width: 400px;
//     }
//   }
  
//   .disclaimer {
//     text-align: center;
//     font-size: 15px;
//     line-height: 30px;
//     letter-spacing: 0.01em;
//     font-weight: normal;
//     margin-top: 2px;
  
//     @media (min-width: 768px) {
//       font-size: 25px;
//       line-height: 55px;
//     }
//   }
  
//   .finalDescription {
//     text-align: right;
//     font-size: 20px;
//     line-height: 30px;
//     letter-spacing: 0.01em;
//     font-weight: normal;
//     max-width: 95%;
//     margin: 0 auto;
//     text-align: center;
//     margin-top: 4px;
  
//     @media (min-width: 768px) {
//       font-size: 30px;
//       line-height: 55px;
//     }
//   }
  


.container {
  background-color: black;
  padding: var(--space-xl) var(--space-xs);
  color: white;
  direction: rtl;
  &.ltr{
    direction: ltr;
  }
}

.border {
  max-width: 1015px;
  margin: 0 auto;
  border: 6px dashed #FF5E5E;
  border-radius: 33px;
  padding: var(--space-xs) var(--space-sm);
}

.title {
  text-align: center;
  font-size: var(--fs-medium);
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

.faqContainer {
  padding: var(--space-lg) 0;
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
}

.faqItem {
  margin-bottom: var(--space-xs);
}

.faqQuestion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-xs);
  text-align: right;
  cursor: pointer;
}

.icon {
  min-width: clamp(20px, 3vw, 37px);
  height: clamp(20px, 3vw, 37px);
}

.questionText {
  font-size: var(--fs-large);
  line-height: 1.5;
  font-weight: normal;
  letter-spacing: 0.01em;
  &.ltr{
    text-align: left;
  }
}

.questionText span {
  margin-right: var(--space-xs);
}

.faqAnswer {
  text-align: right;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  overflow: hidden;
}

.expanded {
  max-height: 1000px;
  opacity: 1;
}

.collapsed {
  max-height: 0;
  opacity: 0;
}

.answerText {
  font-size: var(--fs-medium);
  line-height: 1.5;
  font-weight: normal;
  text-align: right;
  margin-top: var(--space-xs);
  &.ltr{
    text-align: left;
  }
}

.description {
  text-align: right;
  font-size: var(--fs-large);
  line-height: 1.5;
  font-family: 'Rubik', sans-serif;
  font-weight: 400; 
  &.ltr{
    text-align: left;
  }
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 95%;
  gap: var(--space-md);
  margin: var(--space-xs) auto var(--space-lg);
}

.cta {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #1B6F22 19.8%, #30C43C 47.38%, #28A432 73.15%);
  width: 100%;
  height: clamp(88px, 10vw, 128px);
  border-radius: 90px;
  position: relative;
}

.ctaButton {
  font-size: var(--fs-large);
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: 0.01em;
  text-align: center;
  margin-right: var(--space-xs);
  color: white;
  background-color: transparent;
  border: none;
}

.daysContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  border: 1px solid black;
  height: clamp(90px, 12vw, 130px);
  width: clamp(90px, 12vw, 133px);
  border-radius: 50%;
}

.daysNumber {
  text-align: right;
  font-size: var(--fs-large);
  font-weight: 600;
  margin-top: -2px;
}

.daysText {
  text-align: right;
  font-size: var(--fs-small);
  line-height: 1;
  letter-spacing: 0.01em;
  font-weight: bold;
  text-align: center;
}

.startButton {
  background-color: #FF4747;
  color: white;
  font-size: var(--fs-large);
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.04em;
  text-align: center;
  width: 100%;
  height: clamp(70px, 8vw, 115px);
  border-radius: 12px;
  margin-bottom: var(--space-xs);
}

.cardImage {
  background-image: url('../../../../public/assets/cards.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 40px;
  max-width: 400px;
  margin: 0 auto;
  padding: var(--space-xs) 0;
}

.disclaimer {
  text-align: center;
  font-size: var(--fs-small);
  line-height: 1.5;
  letter-spacing: 0.01em;
  font-weight: normal;
  margin-top: var(--space-xs);
}

.finalDescription {
  text-align: center;
  font-size: var(--fs-medium);
  line-height: 1.5;
  letter-spacing: 0.01em;
  font-weight: normal;
  max-width: 95%;
  margin: var(--space-xs) auto 0;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--space-md);
  max-width: 95%;
  margin: var(--space-lg) auto;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: var(--space-xl);
  }
}

.arrowIcon {
  width: 50px;
  height: 83px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: 0;
  }
}

.gradientButton {
  background: linear-gradient(90deg, #1B6F22 19.8%, #30C43C 47.38%, #28A432 73.15%);
  width: 100%;
  height: clamp(70px, 15vw, 92px);
  border-radius: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  @media (min-width: 768px) {
    width: clamp(300px, 50vw, 514.8px);
  }
}

.buttonText {
  color: white;
  font-weight: bold;
  font-size: var(--fs-large);
  line-height: 1.2;
  text-align: center;
  margin-right: 2rem;
  background-color: transparent;
  border: none;
}

.trialDays {
  color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    border: 1px solid black;
    height: 100%;
    aspect-ratio: 1;
    border-radius: 50%;


}

.trialNumber {
  font-size: var(--fs-medium);
  font-weight: 600;
  margin-top: -0.5rem;
}

.trialText {
  font-size: var(--fs-small);
  line-height: 5px;
  text-align: center;
  font-weight: bold;
}